/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* Force text to wrap with hyphen if to long (example long email) */
/*
    Placeholder usage:

    .className {
        @extend %placeHoldername;
    }
*/
.sfPageEditor nav.mobile {
  display: none !important;
}
.sfPageEditor nav.mobile .mobileNavWrapper {
  display: block;
  position: initial;
}
.sfPageEditor header {
  position: relative;
}
.sfPageEditor .RadDockZone {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
  -moz-box-sizing: inherit;
}
.sfPageEditor.sfTemplateEditor nav.mobile {
  display: block !important;
  position: relative;
  right: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
}
.sfPageEditor.sfTemplateEditor nav.mobile .mobileNavWrapper {
  display: block;
  position: initial;
}
.sfPageEditor.sfTemplateEditor header {
  position: relative;
  height: auto;
}
.sfPageEditor.sfTemplateEditor header .topHeader {
  height: auto;
  display: block !important;
}
.sfPageEditor.sfTemplateEditor header .header {
  height: auto;
  display: block !important;
}
.sfPageEditor.sfTemplateEditor main {
  padding-top: 0;
}