/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* Force text to wrap with hyphen if to long (example long email) */
/*
    Placeholder usage:

    .className {
        @extend %placeHoldername;
    }
*/
/* 
Media queries for tablet and mobile (general guidelines for breakpoints globally set in the _variables file) 
Mobile ($S_Max)   Tablet ($M_Min and $M_Max)  Small Desktop ($L_Min and $L_Max)  Large Desktop ($XL_Min - This is the standard for all styles)
*/
/* Small Desktop (switch between simple and mobile nav) */
@media only screen and (max-width: 1099px) {
  .mobileOnly {
    display: block;
  }
  .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer > fieldset > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer > fieldset > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer > fieldset > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .innerContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  button.hamburger {
    display: block;
    top: 0;
    margin-top: 0px;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .mobileNavWrapper .searchBox {
    text-align: right;
  }
  .mobileNavWrapper .searchBox .sfsearchOpen {
    width: 107px;
    line-height: 2;
    font-size: 24px;
    background-color: #00778B;
    border: 0;
    color: #fff;
    margin: 0 0 5px 0;
  }
  .mobileNavWrapper .offices ul {
    text-align: center;
    font-size: 14px;
  }
  .mobileNavWrapper .offices ul li {
    display: inline-block;
  }
  .mobileNavWrapper .offices ul li:before {
    top: 3.5em;
  }
  header {
    height: 60px;
    position: fixed;
  }
  header .sf-Long-text {
    line-height: 1em;
    font-size: 1em;
    color: #000000;
  }
  header .topHeader {
    display: block !important;
    background-color: #fff;
    color: #000000;
  }
  header .topHeader .topHeaderGrid {
    position: relative;
  }
  header .topHeader .topHeaderGrid .logo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    color: #fff;
    min-width: 200px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding-top: 10px;
    padding-left: 30px;
  }
  header .topHeader .topHeaderGrid .logo img {
    width: 100%;
    max-width: 250px;
  }
  header .topHeader .topHeaderGrid .brandline {
    position: absolute;
    top: 36px;
    left: 30px;
    font-size: 14px;
    padding-top: 0;
  }
  header .topHeader .topHeaderGrid .contact nav ul.reset li {
    border-color: #000000;
  }
  header .topHeader .topHeaderGrid .contact nav ul.reset li a {
    color: #000000;
  }
  header .topHeader .topHeaderGrid .contact nav ul.reset li a:hover {
    color: #1A3053;
  }
  header .topHeader .topHeaderGrid .social ul.socialLinks li {
    padding: 0 0.5rem;
  }
  header .topHeader .topHeaderGrid .social ul.socialLinks li a {
    color: #1A3053;
  }
  header .topHeader .topHeaderGrid .social ul.socialLinks li a:hover {
    color: #000000;
  }
  header .header {
    display: none;
  }
  .withNavOpen header {
    position: fixed;
  }
  main {
    padding-top: 60px;
  }
  .teammember .resources.cases .innerContent {
    min-height: inherit;
  }
  .teammember .k-tabstrip-wrapper {
    padding: 2em;
  }
}
@media only screen and (max-width: 1173px) {
  .aboutUsText {
    max-width: unset;
    min-width: unset;
  }
}
/* Tablet */
@media only screen and (max-width: 1024px) {
  #filteredContentResult {
    padding-top: 25px;
  }
  .contactOffices .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .contactOffices .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .contactOffices .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .contactFormContainer .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .contactFormContainer .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .contactFormContainer .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .programDetailMainContent .grid {
    display: block;
  }
  .programDetailMainContent .col.relatedProgramsDiv {
    margin: 50px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .teammember .bannerGrid .bioTopContent.flexCol {
    padding-bottom: 0px !important;
  }
  .teammember .bannerGrid .bioContact .imgWrapper img {
    position: relative;
    margin-right: 0px;
    -webkit-transform: scale(0.75) !important;
            transform: scale(0.75) !important;
  }
  .pageBanner .bannerImage.twoToneBanner .bgImg {
    margin-right: 0px;
  }
  .teamBanner .twoToneBanner .titleContent .bannerTitle {
    padding-right: 0px;
  }
  .resourcesBanner .twoToneBanner .titleContent .bannerTitle {
    padding-right: 0px;
  }
  .programsDetailBanner .twoToneBanner .titleContent .bannerTitle {
    padding-right: 0px;
  }
  .programsBanner .twoToneBanner .titleContent .bannerTitle {
    padding-right: 0px;
  }
  .bannerTitle {
    word-break: break-word;
  }
  .bannerSubtitle {
    padding-right: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .mainContent {
    padding-right: 0px;
  }
  footer .mainFooter .greyGradientFooter .collaborationFooter .footerWrapperCollaboration .sf_colsIn .grid .toronto {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  footer .mainFooter .greyGradientFooter .collaborationFooter .footerWrapperCollaboration .sf_colsIn .grid .mcmaster {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-top: 50px;
  }
  footer .mainFooter .greyGradientFooter .collaborationFooter .footerWrapperCollaboration .sf_colsIn .grid .dimes {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-top: 50px;
  }
  #wrapper {
    width: 100%;
  }
  .grid.grid-nowrap {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  button.hamburger {
    top: 0;
  }
  header .topHeader .topHeaderGrid {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  header .topHeader .topHeaderGrid .brandline {
    position: static;
    font-size: 12px;
  }
  header .topHeader .topHeaderGrid .logo {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 0;
  }
  header .topHeader .topHeaderGrid .multilingual {
    height: 54px;
    margin: 0;
    position: absolute;
    top: 60px;
    left: 36px;
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    background-color: #64002E;
  }
  header .topHeader .topHeaderGrid .contact {
    position: absolute;
    right: -1.5em;
    top: 60px;
    height: 54px;
    background-color: #1A3053;
    margin-right: 0;
    padding-left: 0.25em;
  }
  header .topHeader .topHeaderGrid .contact nav ul.reset {
    padding: 0 0.4em;
    display: block;
  }
  header .topHeader .topHeaderGrid .contact nav ul.reset li {
    display: block;
    border: 0;
  }
  header .topHeader .topHeaderGrid .contact nav ul.reset li a {
    color: #ffffff;
  }
  header .topHeader .topHeaderGrid .contact nav ul.reset li a:hover {
    color: #000000;
  }
  footer .preFooter .innerContainer .preFooterGrid .sitemap .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  footer .preFooter .innerContainer .preFooterGrid .sitemap .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  html.k-ie.k-ie11 footer .preFooter .innerContainer .preFooterGrid .sitemap .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  footer .preFooter .innerContainer .preFooterGrid .social {
    margin-top: 1em;
  }
  footer .mainFooter .footerGrid .copyright, footer .mainFooter .footerGrid .privacy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
  .searchByInitialContainer .col {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  /* #region TEAM BIO PAGE */
  .teammember .topSection {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap-reverse;
        -ms-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
  }
  .teammember .topSection > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .teammember .topSection > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .teammember .topSection .mainContent {
    width: 100%;
    min-width: 100%;
  }
  .teammember .topSection .secondaryContent {
    width: 100%;
    min-width: 100%;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -".credentials"/2;
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    margin: 0 -"aside"/2;
  }
  .teammember .topSection .secondaryContent > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 ".credentials"/2;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - .credentials);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - .credentials);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - .credentials);
            flex-basis: calc((1 / 1) * 100% - .credentials);
  }
  html.k-ie.k-ie11 .teammember .topSection .secondaryContent > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .teammember .topSection .secondaryContent > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 "aside"/2;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - aside);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - aside);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - aside);
            flex-basis: calc((1 / 2) * 100% - aside);
  }
  html.k-ie.k-ie11 .teammember .topSection .secondaryContent > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .teammember .topSection .secondaryContent section, .teammember .topSection .secondaryContent aside {
    margin-top: 0em;
  }
  /* #endregion TEAM BIO PAGE */
  .sectors > .grid > .col, .expertise > .grid > .col {
    margin: 0.5em;
  }
  .cases.slider .slide .innerContent {
    width: 100%;
    padding: 10em 6em;
  }
  .resources.homepage.newsitems {
    padding-left: inherit;
    border: none;
    margin-bottom: 0;
  }
  .programsSearch .searchCols {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .programsSearch .searchCols > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .programsSearch .searchCols > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
/* Mobile Only */
@media only screen and (max-width: 767px) {
  .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer > fieldset > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer > fieldset > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer > fieldset > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer > fieldset > .grid .keywordHome {
    margin-top: 50px !important;
  }
  .filteredContent.programsFiltered .accordion {
    width: 100%;
  }
  .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionBottom li.field {
    width: 100%;
  }
  .filteredContent.programsFiltered .filtersContainer .keywordSearchContainer {
    width: 100%;
  }
  header .topHeader .topHeaderGrid .brandline {
    display: none;
  }
  .homePeopleSearch .findASearch .k-widget.findASearchInput {
    width: 70vw !important;
  }
  .teammember .bannerGrid {
    position: relative;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding-top: 54px;
  }
  .teammember .bannerGrid .bioTopContent.flexCol {
    width: 100%;
    color: #08263D;
    text-align: center;
    padding: 0px !important;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact {
    text-align: center;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact h1 {
    color: #08263D;
    font-size: 2em;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact a:link, .teammember .bannerGrid .bioTopContent.flexCol .bioContact a:visited, .teammember .bannerGrid .bioTopContent.flexCol .bioContact a:active {
    color: inherit;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact ul.socialLinks {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact ul.socialLinks a {
    color: #1A3053;
  }
  .teammember .bannerGrid .bioTopContent.flexCol .bioContact .offices ul li a {
    color: #1A3053;
  }
  .teammember .bannerGrid .bgImgWrapper.flexCol {
    display: block;
    height: 300px;
  }
  .teammember .bannerGrid .bgImgWrapper.flexCol div.bgImg {
    position: absolute;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .teammember .bannerGrid .bgImgWrapper.flexCol div.bgImg:before {
    content: none;
  }
  .teammember .topSection .secondaryContent {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -"aside"/2;
  }
  .teammember .topSection .secondaryContent > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 "aside"/2;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - aside);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - aside);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - aside);
            flex-basis: calc((1 / 1) * 100% - aside);
  }
  html.k-ie.k-ie11 .teammember .topSection .secondaryContent > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .teammembers.authorView .teammember .content {
    width: 100%;
  }
  .teammembers.authorView .teammember .content ul.roles, .teammembers.authorView .teammember .content span.position, .teammembers.authorView .teammember .content ul.entity {
    display: none;
  }
  blockquote {
    margin: 1em 3em 1em 2.5em;
  }
  .expertise.detailView div.padded.centered, .sector.detailView div.padded.centered, .solution.detailView div.padded.centered {
    padding: 1em 2em;
  }
}
@media only screen and (max-width: 570px) {
  .pageBanner .bannerImage.twoToneBanner .bgImg {
    display: none;
  }
  .pageBanner.homeBanner .bannerImage.twoToneBanner .bgImg {
    display: block;
  }
}
/* XS Mobile Only */
@media only screen and (max-width: 500px) {
  .filteredContent .accordion .accordionBottom .grid.checkboxes .field {
    margin: 5px 0px 5px 0px;
  }
  /* Other Elements */
  .teammembers.authorView .teammember .content {
    width: 100%;
  }
  .teammembers.authorView .teammember .content ul.contactInfo, .teammembers.authorView .teammember .content .offices, .teammembers.authorView .teammember .content ul.roles, .teammembers.authorView .teammember .content span.position, .teammembers.authorView .teammember .content ul.entity {
    display: none;
  }
  /* #endregion TEAM BIO PAGE */
  blockquote {
    margin: 1em 1.5em 1em 1em;
  }
}