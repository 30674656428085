@charset "UTF-8";
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
.filters input[type=checkbox] + label:before, .sf-Long-text ul:not(.reset) > li ul > li:before,
.cmsContent ul:not(.reset) > li ul > li:before, .filters input[type=checkbox] + label:after, .teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li:before, .teammember .topSection .secondaryContent .credentials .credentialsInner .educationitems ul li:before, .sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionTop.active::before, .filteredContent .accordion .accordionTop::before, .filteredContent.programsFiltered .accordionTop.active::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
}

.filters input[type=checkbox] + label:after, .teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li:before, .teammember .topSection .secondaryContent .credentials .credentialsInner .educationitems ul li:before, .sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionTop.active::before, .filteredContent .accordion .accordionTop::before, .filteredContent.programsFiltered .accordionTop.active::before {
  font-weight: 700;
}

.filters input[type=checkbox] + label:before, .sf-Long-text ul:not(.reset) > li ul > li:before,
.cmsContent ul:not(.reset) > li ul > li:before {
  font-weight: 400;
}

.branded, span.pseudoH1 {
  position: relative;
}
.branded:after, span.pseudoH1:after {
  content: "\f111";
  /*position:absolute;
  bottom:-1.1em;*/
  font-family: "Font Awesome 5 Pro";
  color: #1A3053;
  font-size: 25%;
  margin-left: 0.5em;
}
.tertiary.branded:before, span.tertiary.pseudoH1:before {
  background-color: #00557B;
}

a.button, .mixitup-page-list button, .mixContainer button, .filters button, .teammembers .teammember .innerContent .teamBioButton a, .stickyPhone .stickyPhoneInner a, .button, .pageWrapper button,
.pageWrapper input[type=file],
.pageWrapper input[type=reset],
.pageWrapper input[type=submit],
.pageWrapper input[type=button],
footer button,
footer input[type=file],
footer input[type=reset],
footer input[type=submit],
footer input[type=button],
.popup_wrapper button,
.popup_wrapper input[type=file],
.popup_wrapper input[type=reset],
.popup_wrapper input[type=submit],
.popup_wrapper input[type=button] {
  border-radius: 0;
  background-color: #64002E;
  padding: 0.4em 1.5em;
  color: white;
  text-transform: uppercase;
  margin-top: 1em;
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 0px solid #64002E;
  text-decoration: none;
  font-family: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  -webkit-box-shadow: #E4EAED 0px 3px;
          box-shadow: #E4EAED 0px 3px;
}
.mixitup-page-list button:hover, .mixContainer button:hover, .filters button:hover, .teammembers .teammember .innerContent .teamBioButton a:hover, .stickyPhone .stickyPhoneInner a:hover, .button:hover, .pageWrapper button:hover,
.pageWrapper input[type=file]:hover,
.pageWrapper input[type=reset]:hover,
.pageWrapper input[type=submit]:hover,
.pageWrapper input[type=button]:hover,
footer button:hover,
footer input[type=file]:hover,
footer input[type=reset]:hover,
footer input[type=submit]:hover,
footer input[type=button]:hover,
.popup_wrapper button:hover,
.popup_wrapper input[type=file]:hover,
.popup_wrapper input[type=reset]:hover,
.popup_wrapper input[type=submit]:hover,
.popup_wrapper input[type=button]:hover {
  color: #ffffff;
  background-color: #64002E;
  text-decoration: none !important;
}
.mixitup-page-list button.reversed, .mixContainer button.reversed, .filters button.reversed, .teammembers .teammember .innerContent .teamBioButton a.reversed, .stickyPhone .stickyPhoneInner a.reversed, .reversed.button, .pageWrapper button.reversed,
.pageWrapper input.reversed[type=file],
.pageWrapper input.reversed[type=reset],
.pageWrapper input.reversed[type=submit],
.pageWrapper input.reversed[type=button],
footer button.reversed,
footer input.reversed[type=file],
footer input.reversed[type=reset],
footer input.reversed[type=submit],
footer input.reversed[type=button],
.popup_wrapper button.reversed,
.popup_wrapper input.reversed[type=file],
.popup_wrapper input.reversed[type=reset],
.popup_wrapper input.reversed[type=submit],
.popup_wrapper input.reversed[type=button] {
  background-color: none;
  color: #1A3053 !important;
}
.mixitup-page-list button.reversed:hover, .mixContainer button.reversed:hover, .filters button.reversed:hover, .teammembers .teammember .innerContent .teamBioButton a.reversed:hover, .stickyPhone .stickyPhoneInner a.reversed:hover, .reversed.button:hover, .pageWrapper button.reversed:hover,
.pageWrapper input.reversed[type=file]:hover,
.pageWrapper input.reversed[type=reset]:hover,
.pageWrapper input.reversed[type=submit]:hover,
.pageWrapper input.reversed[type=button]:hover,
footer button.reversed:hover,
footer input.reversed[type=file]:hover,
footer input.reversed[type=reset]:hover,
footer input.reversed[type=submit]:hover,
footer input.reversed[type=button]:hover,
.popup_wrapper button.reversed:hover,
.popup_wrapper input.reversed[type=file]:hover,
.popup_wrapper input.reversed[type=reset]:hover,
.popup_wrapper input.reversed[type=submit]:hover,
.popup_wrapper input.reversed[type=button]:hover {
  background-color: #64002E;
  color: white !important;
}
.mixitup-page-list button.reset, .mixContainer button.reset, .filters button.reset, .teammembers .teammember .innerContent .teamBioButton a.reset, .stickyPhone .stickyPhoneInner a.reset, .reset.button, .pageWrapper button.reset,
.pageWrapper input.reset[type=file],
.pageWrapper input.reset[type=reset],
.pageWrapper input.reset[type=submit],
.pageWrapper input.reset[type=button],
footer button.reset,
footer input.reset[type=file],
footer input.reset[type=reset],
footer input.reset[type=submit],
footer input.reset[type=button],
.popup_wrapper button.reset,
.popup_wrapper input.reset[type=file],
.popup_wrapper input.reset[type=reset],
.popup_wrapper input.reset[type=submit],
.popup_wrapper input.reset[type=button] {
  background-color: white;
  color: #64002E;
  border: 0;
}
.mixitup-page-list button.reset:hover, .mixContainer button.reset:hover, .filters button.reset:hover, .teammembers .teammember .innerContent .teamBioButton a.reset:hover, .stickyPhone .stickyPhoneInner a.reset:hover, .reset.button:hover, .pageWrapper button.reset:hover,
.pageWrapper input.reset[type=file]:hover,
.pageWrapper input.reset[type=reset]:hover,
.pageWrapper input.reset[type=submit]:hover,
.pageWrapper input.reset[type=button]:hover,
footer button.reset:hover,
footer input.reset[type=file]:hover,
footer input.reset[type=reset]:hover,
footer input.reset[type=submit]:hover,
footer input.reset[type=button]:hover,
.popup_wrapper button.reset:hover,
.popup_wrapper input.reset[type=file]:hover,
.popup_wrapper input.reset[type=reset]:hover,
.popup_wrapper input.reset[type=submit]:hover,
.popup_wrapper input.reset[type=button]:hover {
  color: #333232 !important;
}
.mixitup-page-list button.submit, .mixContainer button.submit, .filters button.submit, .teammembers .teammember .innerContent .teamBioButton a.submit, .stickyPhone .stickyPhoneInner a.submit, .submit.button, .pageWrapper button.submit,
.pageWrapper input.submit[type=file],
.pageWrapper input.submit[type=reset],
.pageWrapper input.submit[type=submit],
.pageWrapper input.submit[type=button],
footer button.submit,
footer input.submit[type=file],
footer input.submit[type=reset],
footer input.submit[type=submit],
footer input.submit[type=button],
.popup_wrapper button.submit,
.popup_wrapper input.submit[type=file],
.popup_wrapper input.submit[type=reset],
.popup_wrapper input.submit[type=submit],
.popup_wrapper input.submit[type=button] {
  text-align: right;
}
.mixitup-page-list button.primary, .mixContainer button.primary, .filters button.primary, .teammembers .teammember .innerContent .teamBioButton a.primary, .stickyPhone .stickyPhoneInner a.primary, .primary.button, .pageWrapper button.primary,
.pageWrapper input.primary[type=file],
.pageWrapper input.primary[type=reset],
.pageWrapper input.primary[type=submit],
.pageWrapper input.primary[type=button],
footer button.primary,
footer input.primary[type=file],
footer input.primary[type=reset],
footer input.primary[type=submit],
footer input.primary[type=button],
.popup_wrapper button.primary,
.popup_wrapper input.primary[type=file],
.popup_wrapper input.primary[type=reset],
.popup_wrapper input.primary[type=submit],
.popup_wrapper input.primary[type=button] {
  background-color: #64002E;
}

.resources .innerContent .itemContent a.readMore {
  color: #1A3053;
  font-weight: 600;
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 0.2rem 0.4rem;
  border-bottom: 2px solid #1A3053;
}
.resources .innerContent .itemContent a.readMore:hover, .resources .innerContent .itemContent a.readMore:active, .resources .innerContent .itemContent a.readMore:focus {
  color: #64002E;
}

.newsletters li a .newsletterIssue, .sf-list--expandable .sf-list-item a, .awardrankings .awardTop, .rankings .awardTop, .teammember .topSection .secondaryContent .credentials .credentialsInner {
  -webkit-box-shadow: 3px 5px 10px 1px rgba(51, 50, 50, 0.6);
  box-shadow: 3px 5px 10px 1px rgba(51, 50, 50, 0.6);
  padding: 0.5em;
}

/* Force text to wrap with hyphen if to long (example long email) */
a[href^=mailto] {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  white-space: unset;
}

.pageBanner .bannerImage .slick-slider .slide .bannerImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.homeSlideshow .slide {
  position: relative;
}
.homeSlideshow .slide .coverImg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
}
.homeSlideshow .slide .coverImg img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*
    Placeholder usage:

    .className {
        @extend %placeHoldername;
    }
*/
article.projectCard .bgImgWrapper, .factgroups .bgImgWrapper .bgImg, .factgroups .bgImgWrapper, .newsletters li a .newsletterIssue:before, .featuredcontentgroups div.item .contentWrapper:before, .featuredcontentgroups div.item .coverImg, .awardrankings .awardTop:before, .rankings .awardTop:before, .expertises.accordionUL ul.level1 li.hasChildren.active:before, .sectors.accordionUL ul.level1 li.hasChildren.active:before, .sectors > .grid .col .bgImgWrapper a, .expertises > .grid .col .bgImgWrapper a, .teammembers.authorView .teammember .hoverContent, .teammembers.authorView .teammember .content, .teammembers .teammember .bgImgWrapper a span.bgImg, .teammembers .teammember .innerContent .bgImgWrapper .bgImg, div.bgImgWrapper a span.bgImg, .pageBanner .bannerImage .bgImg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
}

/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
/* ### Font Awesome Icons ###*/
/* ### Navigational Icons */
/* ### Other Icons */
/* XS Mobile (max width) */
/* Mobile (min width) */
/* Mobile (max width) */
/* Tablets (min width) */
/* Tablets (max width) */
/* Small Desktop  (min width) */
/* Small Desktop (max width) */
/* Large Desktop (min width) - THIS IS THE DEFAULT STYLES */
/* Large Desktop Breakpoint where we need to apply global innerWrapper Padding */
/*Set maximum viewable area of the site */
/*@if(type-of($Columns) == list) {
    // loop through values and assign to columns
    @each $col in $Columns {
    }
}*/
.newGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newGrid > .col {
  /*flex: 1 0 auto;*/
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  /*order: 0;*/
  min-width: 0;
  max-width: 100%;
  padding: 0 0.5rem;
  vertical-align: top;
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}

/* ### REM FALLBACK ### */
/*---------------   
--Mixin: overlay
----------------- */
/* Old Font Size Function
@mixin font-size($sizeValue, $line: 1.5){
  font-size: ($sizeValue) + px;
  line-height: ($line * $sizeValue) + px;
  font-size: ($sizeValue / 10) *.625 + rem;
  line-height: (($line * $sizeValue) / 10) *.625 + rem;
}
*/
/*
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

@include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
.filters input[type=checkbox] + label:after, .teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li:before, .teammember .topSection .secondaryContent .credentials .credentialsInner .educationitems ul li:before, .sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionTop.active::before, .filteredContent .accordion .accordionTop::before, .filteredContent.programsFiltered .accordionTop.active::before, .filters input[type=checkbox] + label:before, .sf-Long-text ul:not(.reset) > li ul > li:before,
.cmsContent ul:not(.reset) > li ul > li:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
}

.filters input[type=checkbox] + label:after, .teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li:before, .teammember .topSection .secondaryContent .credentials .credentialsInner .educationitems ul li:before, .sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionTop.active::before, .filteredContent .accordion .accordionTop::before, .filteredContent.programsFiltered .accordionTop.active::before {
  font-weight: 700;
}

.filters input[type=checkbox] + label:before, .sf-Long-text ul:not(.reset) > li ul > li:before,
.cmsContent ul:not(.reset) > li ul > li:before {
  font-weight: 400;
}

.branded, span.pseudoH1 {
  position: relative;
}
.branded:after, span.pseudoH1:after {
  content: "\f111";
  /*position:absolute;
  bottom:-1.1em;*/
  font-family: "Font Awesome 5 Pro";
  color: #1A3053;
  font-size: 25%;
  margin-left: 0.5em;
}
.tertiary.branded:before, span.tertiary.pseudoH1:before {
  background-color: #00557B;
}

a.button, .mixitup-page-list button, .mixContainer button, .filters button, .teammembers .teammember .innerContent .teamBioButton a, .stickyPhone .stickyPhoneInner a, .button, .pageWrapper button,
.pageWrapper input[type=file],
.pageWrapper input[type=reset],
.pageWrapper input[type=submit],
.pageWrapper input[type=button],
footer button,
footer input[type=file],
footer input[type=reset],
footer input[type=submit],
footer input[type=button],
.popup_wrapper button,
.popup_wrapper input[type=file],
.popup_wrapper input[type=reset],
.popup_wrapper input[type=submit],
.popup_wrapper input[type=button] {
  border-radius: 0;
  background-color: #64002E;
  padding: 0.4em 1.5em;
  color: white;
  text-transform: uppercase;
  margin-top: 1em;
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 0px solid #64002E;
  text-decoration: none;
  font-family: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
  -webkit-box-shadow: #E4EAED 0px 3px;
          box-shadow: #E4EAED 0px 3px;
}
.mixitup-page-list button:hover, .mixContainer button:hover, .filters button:hover, .teammembers .teammember .innerContent .teamBioButton a:hover, .stickyPhone .stickyPhoneInner a:hover, .button:hover, .pageWrapper button:hover,
.pageWrapper input[type=file]:hover,
.pageWrapper input[type=reset]:hover,
.pageWrapper input[type=submit]:hover,
.pageWrapper input[type=button]:hover,
footer button:hover,
footer input[type=file]:hover,
footer input[type=reset]:hover,
footer input[type=submit]:hover,
footer input[type=button]:hover,
.popup_wrapper button:hover,
.popup_wrapper input[type=file]:hover,
.popup_wrapper input[type=reset]:hover,
.popup_wrapper input[type=submit]:hover,
.popup_wrapper input[type=button]:hover {
  color: #ffffff;
  background-color: #64002E;
  text-decoration: none !important;
}
.mixitup-page-list button.reversed, .mixContainer button.reversed, .filters button.reversed, .teammembers .teammember .innerContent .teamBioButton a.reversed, .stickyPhone .stickyPhoneInner a.reversed, .reversed.button, .pageWrapper button.reversed,
.pageWrapper input.reversed[type=file],
.pageWrapper input.reversed[type=reset],
.pageWrapper input.reversed[type=submit],
.pageWrapper input.reversed[type=button],
footer button.reversed,
footer input.reversed[type=file],
footer input.reversed[type=reset],
footer input.reversed[type=submit],
footer input.reversed[type=button],
.popup_wrapper button.reversed,
.popup_wrapper input.reversed[type=file],
.popup_wrapper input.reversed[type=reset],
.popup_wrapper input.reversed[type=submit],
.popup_wrapper input.reversed[type=button] {
  background-color: none;
  color: #1A3053 !important;
}
.mixitup-page-list button.reversed:hover, .mixContainer button.reversed:hover, .filters button.reversed:hover, .teammembers .teammember .innerContent .teamBioButton a.reversed:hover, .stickyPhone .stickyPhoneInner a.reversed:hover, .reversed.button:hover, .pageWrapper button.reversed:hover,
.pageWrapper input.reversed[type=file]:hover,
.pageWrapper input.reversed[type=reset]:hover,
.pageWrapper input.reversed[type=submit]:hover,
.pageWrapper input.reversed[type=button]:hover,
footer button.reversed:hover,
footer input.reversed[type=file]:hover,
footer input.reversed[type=reset]:hover,
footer input.reversed[type=submit]:hover,
footer input.reversed[type=button]:hover,
.popup_wrapper button.reversed:hover,
.popup_wrapper input.reversed[type=file]:hover,
.popup_wrapper input.reversed[type=reset]:hover,
.popup_wrapper input.reversed[type=submit]:hover,
.popup_wrapper input.reversed[type=button]:hover {
  background-color: #64002E;
  color: white !important;
}
.mixitup-page-list button.reset, .mixContainer button.reset, .filters button.reset, .teammembers .teammember .innerContent .teamBioButton a.reset, .stickyPhone .stickyPhoneInner a.reset, .reset.button, .pageWrapper button.reset,
.pageWrapper input.reset[type=file],
.pageWrapper input.reset[type=reset],
.pageWrapper input.reset[type=submit],
.pageWrapper input.reset[type=button],
footer button.reset,
footer input.reset[type=file],
footer input.reset[type=reset],
footer input.reset[type=submit],
footer input.reset[type=button],
.popup_wrapper button.reset,
.popup_wrapper input.reset[type=file],
.popup_wrapper input.reset[type=reset],
.popup_wrapper input.reset[type=submit],
.popup_wrapper input.reset[type=button] {
  background-color: white;
  color: #64002E;
  border: 0;
}
.mixitup-page-list button.reset:hover, .mixContainer button.reset:hover, .filters button.reset:hover, .teammembers .teammember .innerContent .teamBioButton a.reset:hover, .stickyPhone .stickyPhoneInner a.reset:hover, .reset.button:hover, .pageWrapper button.reset:hover,
.pageWrapper input.reset[type=file]:hover,
.pageWrapper input.reset[type=reset]:hover,
.pageWrapper input.reset[type=submit]:hover,
.pageWrapper input.reset[type=button]:hover,
footer button.reset:hover,
footer input.reset[type=file]:hover,
footer input.reset[type=reset]:hover,
footer input.reset[type=submit]:hover,
footer input.reset[type=button]:hover,
.popup_wrapper button.reset:hover,
.popup_wrapper input.reset[type=file]:hover,
.popup_wrapper input.reset[type=reset]:hover,
.popup_wrapper input.reset[type=submit]:hover,
.popup_wrapper input.reset[type=button]:hover {
  color: #333232 !important;
}
.mixitup-page-list button.submit, .mixContainer button.submit, .filters button.submit, .teammembers .teammember .innerContent .teamBioButton a.submit, .stickyPhone .stickyPhoneInner a.submit, .submit.button, .pageWrapper button.submit,
.pageWrapper input.submit[type=file],
.pageWrapper input.submit[type=reset],
.pageWrapper input.submit[type=submit],
.pageWrapper input.submit[type=button],
footer button.submit,
footer input.submit[type=file],
footer input.submit[type=reset],
footer input.submit[type=submit],
footer input.submit[type=button],
.popup_wrapper button.submit,
.popup_wrapper input.submit[type=file],
.popup_wrapper input.submit[type=reset],
.popup_wrapper input.submit[type=submit],
.popup_wrapper input.submit[type=button] {
  text-align: right;
}
.mixitup-page-list button.primary, .mixContainer button.primary, .filters button.primary, .teammembers .teammember .innerContent .teamBioButton a.primary, .stickyPhone .stickyPhoneInner a.primary, .primary.button, .pageWrapper button.primary,
.pageWrapper input.primary[type=file],
.pageWrapper input.primary[type=reset],
.pageWrapper input.primary[type=submit],
.pageWrapper input.primary[type=button],
footer button.primary,
footer input.primary[type=file],
footer input.primary[type=reset],
footer input.primary[type=submit],
footer input.primary[type=button],
.popup_wrapper button.primary,
.popup_wrapper input.primary[type=file],
.popup_wrapper input.primary[type=reset],
.popup_wrapper input.primary[type=submit],
.popup_wrapper input.primary[type=button] {
  background-color: #64002E;
}

.resources .innerContent .itemContent a.readMore {
  color: #1A3053;
  font-weight: 600;
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 0.2rem 0.4rem;
  border-bottom: 2px solid #1A3053;
}
.resources .innerContent .itemContent a.readMore:hover, .resources .innerContent .itemContent a.readMore:active, .resources .innerContent .itemContent a.readMore:focus {
  color: #64002E;
}

.newsletters li a .newsletterIssue, .sf-list--expandable .sf-list-item a, .awardrankings .awardTop, .rankings .awardTop, .teammember .topSection .secondaryContent .credentials .credentialsInner {
  -webkit-box-shadow: 3px 5px 10px 1px rgba(51, 50, 50, 0.6);
  box-shadow: 3px 5px 10px 1px rgba(51, 50, 50, 0.6);
  padding: 0.5em;
}

/* Force text to wrap with hyphen if to long (example long email) */
a[href^=mailto] {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  white-space: unset;
}

.pageBanner .bannerImage .slick-slider .slide .bannerImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.homeSlideshow .slide {
  position: relative;
}
.homeSlideshow .slide .coverImg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
}
.homeSlideshow .slide .coverImg img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*
    Placeholder usage:

    .className {
        @extend %placeHoldername;
    }
*/
article.projectCard .bgImgWrapper, .factgroups .bgImgWrapper .bgImg, .factgroups .bgImgWrapper, .newsletters li a .newsletterIssue:before, .featuredcontentgroups div.item .contentWrapper:before, .featuredcontentgroups div.item .coverImg, .awardrankings .awardTop:before, .rankings .awardTop:before, .expertises.accordionUL ul.level1 li.hasChildren.active:before, .sectors.accordionUL ul.level1 li.hasChildren.active:before, .sectors > .grid .col .bgImgWrapper a, .expertises > .grid .col .bgImgWrapper a, .teammembers.authorView .teammember .hoverContent, .teammembers.authorView .teammember .content, .teammembers .teammember .bgImgWrapper a span.bgImg, .teammembers .teammember .innerContent .bgImgWrapper .bgImg, div.bgImgWrapper a span.bgImg, .pageBanner .bannerImage .bgImg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
}

/*$base-unit: 12px;*/
.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 -1rem 0 -1rem;
}
.grid.grid-nogutter {
  margin: 0;
}
.grid.grid-nogutter > .col {
  padding: 0;
  margin: 0;
}
.grid.grid-nowrap {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.grid.grid-doublegutter > .col {
  padding: 0 4rem 0 4rem;
}
.featureFirst .grid .col:first-child, .grid.featureFirst .col:first-child {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 1) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
          flex-basis: calc((1 / 1) * 100% - 1rem);
}
html.k-ie.k-ie11 .featureFirst .grid .col:first-child > *, html.k-ie.k-ie11 .grid.featureFirst .col:first-child > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.grid:not(.col) {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.grid > .col {
  margin: 0 1rem 0 1rem;
}

.grid[data-gutter-width] {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.sfPageEditor .grid[data-gutter-width] > .col {
  -webkit-box-flex: 1 !important;
  -webkit-flex-grow: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
  -webkit-flex-shrink: 0 !important;
      -ms-flex-negative: 0 !important;
          flex-shrink: 0 !important;
  -webkit-flex-basis: 0 !important;
      -ms-flex-preferred-size: 0 !important;
          flex-basis: 0 !important;
}
.grid[data-gutter-width]:not([data-media-breakpoint]) {
  /*@media (max-width: $M_Max) {
      @extend %colStackedStyles;
  }*/
}
@media (max-width: 1099px) and (max-width: 1099px) {
  .grid[data-gutter-width][data-media-breakpoint="1299px"] {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid[data-gutter-width][data-media-breakpoint="1299px"] > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}
@media (max-width: 1024px) and (max-width: 1024px) {
  .grid[data-gutter-width][data-media-breakpoint="1024px"] {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid[data-gutter-width][data-media-breakpoint="1024px"] > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .grid[data-gutter-width][data-media-breakpoint="767px"] {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid[data-gutter-width][data-media-breakpoint="767px"] > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}
@media (max-width: 500px) and (max-width: 500px) {
  .grid[data-gutter-width][data-media-breakpoint="500px"] {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .grid[data-gutter-width][data-media-breakpoint="500px"] > .col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: initial !important;
    -webkit-flex-basis: initial !important;
        -ms-flex-preferred-size: initial !important;
            flex-basis: initial !important;
  }
}

.col {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  max-width: 100%;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
html.k-ie.k-ie11 .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}

/*.col-align-top {
    align-self: flex-start;
}

.col-align-bottom {
    align-self: flex-end;
}

.col-align-middle {
    align-self: center;
}*/
.grid-start,
.col-top {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.grid-end,
.col-bottom {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.grid-center,
.col-middle {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.grid-evenly,
.col-evenly {
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.grid-around,
.col-around {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}

.grid-between,
.col-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.col-align-start,
.grid-align-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.col-align-end,
.grid-align-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.col-align-center,
.grid-align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.col-align-stretch,
.grid-align-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.col-align-baseline,
.grid-align-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.col-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1;
}

.col-last {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
}

.col-fixed {
  -webkit-box-flex: initial;
  -webkit-flex: initial;
      -ms-flex: initial;
          flex: initial;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
}

.col-auto-width {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}

.col-grow-2 {
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}

.col-grow-3 {
  -webkit-box-flex: 3;
  -webkit-flex-grow: 3;
      -ms-flex-positive: 3;
          flex-grow: 3;
}

.col-grow-4 {
  -webkit-box-flex: 4;
  -webkit-flex-grow: 4;
      -ms-flex-positive: 4;
          flex-grow: 4;
}

.col-grow-5 {
  -webkit-box-flex: 5;
  -webkit-flex-grow: 5;
      -ms-flex-positive: 5;
          flex-grow: 5;
}

.col-grow-6 {
  -webkit-box-flex: 6;
  -webkit-flex-grow: 6;
      -ms-flex-positive: 6;
          flex-grow: 6;
}

.col-grow-7 {
  -webkit-box-flex: 7;
  -webkit-flex-grow: 7;
      -ms-flex-positive: 7;
          flex-grow: 7;
}

.col-grow-8 {
  -webkit-box-flex: 8;
  -webkit-flex-grow: 8;
      -ms-flex-positive: 8;
          flex-grow: 8;
}

.col-grow-9 {
  -webkit-box-flex: 9;
  -webkit-flex-grow: 9;
      -ms-flex-positive: 9;
          flex-grow: 9;
}

.col-grow-10 {
  -webkit-box-flex: 10;
  -webkit-flex-grow: 10;
      -ms-flex-positive: 10;
          flex-grow: 10;
}

.col-grow-11 {
  -webkit-box-flex: 11;
  -webkit-flex-grow: 11;
      -ms-flex-positive: 11;
          flex-grow: 11;
}

.col-1 {
  -webkit-flex-basis: 8.3333333333%;
      -ms-flex-preferred-size: 8.3333333333%;
          flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-flex-basis: 16.6666666667%;
      -ms-flex-preferred-size: 16.6666666667%;
          flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-flex-basis: 25%;
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-flex-basis: 33.3333333333%;
      -ms-flex-preferred-size: 33.3333333333%;
          flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-flex-basis: 41.6666666667%;
      -ms-flex-preferred-size: 41.6666666667%;
          flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-flex-basis: 58.3333333333%;
      -ms-flex-preferred-size: 58.3333333333%;
          flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-flex-basis: 66.6666666667%;
      -ms-flex-preferred-size: 66.6666666667%;
          flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-flex-basis: 75%;
      -ms-flex-preferred-size: 75%;
          flex-basis: 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-flex-basis: 83.3333333333%;
      -ms-flex-preferred-size: 83.3333333333%;
          flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-flex-basis: 91.6666666667%;
      -ms-flex-preferred-size: 91.6666666667%;
          flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 1099px) {
  .innerContainer {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  /*.grid {
      &:not([data-gutter-width]):not(.grid-nowrap) { // only apply to NON-resizable grids
          .col {
              flex: 100%;
              max-width: 100%;
          }
      }
  }*/
  .grid-reverse-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .grid-reverse-rows {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
}
.col.col-center-y {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.innerContainer {
  margin: 0 auto;
  max-width: 100%;
  width: -webkit-calc(1300px + 4rem);
  width: calc(1300px + 4rem);
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 767px) {
  .innerContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.fullWidthContainer {
  padding-left: 5rem;
  padding-right: 5rem;
}
@media (max-width: 1500px) {
  .fullWidthContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .fullWidthContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.innerContainer .innerContainer,
.fullWidthContainer .innerContainer {
  width: 1300px;
  margin: 0 auto;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.gridTestingPage .bd .grid {
  padding: 2px 0;
  /*border: 2px solid purple;*/
  background-color: rgba(218, 112, 214, 0.5);
}
.gridTestingPage .bd .grid .col {
  /*border: 2px dashed grey;*/
  min-height: 200px;
  background-color: rgba(127, 255, 212, 0.5);
}

/*@import '_typography.scss';*/
::-moz-selection {
  background: #00D3FF; /* WebKit/Blink Browsers */
}
::selection {
  background: #00D3FF; /* WebKit/Blink Browsers */
}

::-webkit-input-placeholder {
  color: #64002E;
}

:-moz-placeholder {
  color: #64002E;
}

::-moz-placeholder {
  color: #64002E;
}

:-ms-input-placeholder {
  color: #64002E;
}

::-moz-selection {
  background: #64002E;
}

.programsDisclaimer {
  margin-top: 100px;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/*#region TYPOGRAPHY SECTION*/
html {
  font-family: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 400;
  color: #1A3053;
  font-style: normal;
  line-height: 1.4em;
}
html {
  font-size: 16px;
}
@media screen and (min-width: 1025px) {
  html {
    font-size: -webkit-calc(16px + 4 * ((100vw - 1025px) / 275));
    font-size: calc(16px + 4 * ((100vw - 1025px) / 275));
  }
}
@media screen and (min-width: 1300px) {
  html {
    font-size: 20px;
  }
}

main {
  position: relative;
  z-index: 0;
}

html, form {
  height: 100%;
}

body {
  overflow-x: hidden;
  background-color: #ffffff;
  color: #1A3053;
  font-family: "canada-type-gibson" sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 2em;
  word-break: break-word;
}

span.seperator {
  padding: 0 7px;
  color: #0e426a;
  word-break: break-word;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  color: #1A3053;
  font-family: "canada-type-gibson", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1em;
  margin: 1em 0 1em 0;
  padding: 0;
}
h1.centered, h2.centered, h3.centered, h4.centered, h5.centered, h6.centered {
  text-align: center;
}

h1 {
  font-size: 55px;
  font-family: "canada-type-gibson", sans-serif;
  color: #08263D;
  margin: 0.75em 0 0.5em 0;
  word-break: break-word;
}

h2 {
  font-size: 2em;
  font-weight: 700;
  word-break: break-word;
}

h3 {
  font-size: 36px;
  font-weight: 700;
  word-break: break-word;
}

h4 {
  font-size: 1em;
  font-weight: 700;
  word-break: break-word;
}

h5 {
  /*font-size: .83em; // popular size*/
  font-size: 1em;
  font-weight: 700;
  word-break: break-word;
}

h6 {
  /*font-size: 18px;*/
  /*font-size: .75em; // popular size*/
  font-size: 0.75em;
  font-weight: 700;
  word-break: break-word;
}

p {
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2em;
  word-break: break-word;
}

li {
  font-size: 1em;
  font-style: normal;
  word-break: break-word;
}

strong, b {
  font-weight: 700;
  color: #00557B;
  font-style: normal;
}

em, i {
  font-style: italic;
}

sup {
  vertical-align: super;
  font-size: 50%;
  font-style: normal;
}

sub {
  vertical-align: sub;
  font-size: 50%;
  font-style: normal;
}

blockquote {
  margin: 1em 10em 1em 9em;
  padding: 2em 1em;
  position: relative;
  border-top: 1px solid #00557B;
  border-bottom: 1px solid #00557B;
  color: #00557B;
  text-align: center;
}
blockquote p {
  color: #00557B;
  font-weight: 700;
  font-style: normal;
}

a {
  color: #64002E;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  word-break: break-word;
  text-decoration: underline;
}

.aboutUsText {
  max-width: 500px;
  min-width: 500px;
}

a:link {
  /*color: $primaryColour;*/
}

a:visited {
  /*color: $primaryColour;*/
}

a:hover {
  color: #1A3053;
  text-decoration: underline;
}

a:active {
  color: #64002E;
}

hr {
  border: none;
  height: 1px;
  margin: 2em 0;
  background-color: #E4EAED;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
  word-break: break-word;
}
ul li,
ol li {
  list-style: none;
}

button {
  cursor: pointer;
  word-break: break-word;
}

/*#endregion TYPOGRAPHY SECTION*/
/*#region LAYOUT SECTION*/
/*Sticky Footer Items */
body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

#wrapper {
  overflow: hidden;
  height: auto !important;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
.k-ie11 #wrapper {
  -webkit-box-flex: inherit;
  -webkit-flex: inherit;
      -ms-flex: inherit;
          flex: inherit;
}

.contentPadding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.columnMarginRight {
  margin-right: 2.5em !important;
}

.columnMarginLeft {
  margin-left: 2.5em !important;
}

.searchCols > .col:first-child {
  margin-left: 0px;
}
.searchCols > .col:last-child {
  margin-right: 0px;
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}

/* End Sticky Footer Items */
header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: white;
}
header .topHeader {
  display: none;
}
header .sf-Long-text {
  line-height: 1em;
  font-size: 1em;
  color: #ffffff;
}
header .header {
  padding: 0;
  z-index: 100;
  color: #fff;
}
header .header .social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}
header .header .social .sf-Long-text {
  height: 100%;
}
header .header .social em, header .header .social i {
  font-style: normal !important;
}
header .header .social ul {
  height: 100%;
}
header .header .social ul li {
  border-left: 1px solid #fff;
  padding: 0 1rem;
}
header .header .social ul li a {
  color: #333232;
}
header .header .social ul li a:hover {
  color: #1A3053;
}
header .header .social ul li:last-child {
  border-right: 1px solid #fff;
}
header .header.stuck {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  -webkit-box-shadow: 0 0 37px -36px rgba(7, 28, 44, 0.8);
          box-shadow: 0 0 37px -36px rgba(7, 28, 44, 0.8);
}
header .header .headerGrid {
  /*display: flex;*/
  /*@include grid("auto", 1rem);*/
}
header .header .headerGrid .logo {
  color: #fff;
  min-width: 250px;
  padding-bottom: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
header .header .headerGrid .menu {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
          align-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
header .header .headerGrid .menu nav {
  min-width: 75%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
          align-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
header .header .headerGrid .searchAndSocial {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
header .header .headerGrid .searchAndSocial button {
  margin: 0px;
  margin-left: 20px;
}
header .header .headerGrid .searchAndSocial .searchBarHeader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
header .header .headerGrid .searchAndSocial .searchBarHeader button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-color: #D8D8D8;
  background-color: #D8D8D8;
  height: 40px;
  color: #64002E;
  padding-right: 20px;
}
header .header .headerGrid .searchAndSocial .searchBarHeader input.ui-autocomplete-input {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-color: #D8D8D8;
  background-color: #D8D8D8;
  height: 40px;
  color: #64002E;
  background-image: none;
  width: 250px;
}
header .header .headerGrid .searchAndSocial .searchBarHeader input.ui-autocomplete-input::-webkit-input-placeholder { /* Edge */
  color: #64002E;
}
header .header .headerGrid .searchAndSocial .searchBarHeader input.ui-autocomplete-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #64002E;
}
header .header .headerGrid .searchAndSocial .searchBarHeader input.ui-autocomplete-input:-moz-placeholder {
  color: #64002E;
}
header .header .headerGrid .searchAndSocial .searchBarHeader input.ui-autocomplete-input:focus {
  outline: none;
}
header .header .headerGrid .search {
  margin-top: 0.3em;
  /*height: 2em;*/
  padding: 0.25em 0;
}
header .header .headerGrid .search .form-inline .searchBox button {
  margin: 0;
  border: none;
  background: none;
  color: #08263D;
  font-size: 1.25em;
  position: relative;
  font-weight: 300;
  padding-left: 0.6rem;
}
header .header .headerGrid .search .form-inline .searchBox button:before {
  content: " ";
  width: 1px;
  display: block;
  background-color: #1A3053;
  position: relative;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 1400px) {
  header .header .headerGrid .search {
    display: none;
  }
}

.filteredContent.programsFiltered .filtersContainer .keywordSearchContainer {
  width: 70%;
}
.filteredContent.programsFiltered .filtersContainer .keywordSearchContainer .keywordSearch:-ms-input-placeholder, .filteredContent.programsFiltered .filtersContainer .keywordSearchContainer .keywordSearch:-ms-input-placeholder, .filteredContent.programsFiltered .filtersContainer .keywordSearchContainer .keywordSearch::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  text-transform: initial;
  opacity: 1; /* Firefox */
}
.filteredContent.programsFiltered .filtersContainer .keywordSearchContainer .keywordSearch::-ms-input-placeholder, .filteredContent.programsFiltered .filtersContainer .keywordSearchContainer .keywordSearch:-ms-input-placeholder, .filteredContent.programsFiltered .filtersContainer .keywordSearchContainer .keywordSearch::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  text-transform: initial;
  opacity: 1; /* Firefox */
}
.filteredContent.programsFiltered .filtersContainer .keywordSearchContainer .keywordSearch::placeholder, .filteredContent.programsFiltered .filtersContainer .keywordSearchContainer .keywordSearch:-ms-input-placeholder, .filteredContent.programsFiltered .filtersContainer .keywordSearchContainer .keywordSearch::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  text-transform: initial;
  opacity: 1; /* Firefox */
}
.filteredContent.programsFiltered .accordionTop.active {
  background-color: #1A3053;
  color: white;
}
.filteredContent.programsFiltered .accordionTop.active::before {
  content: "\f068";
  position: absolute;
  right: 0%;
  margin-right: 25px;
  z-index: 2;
  display: block;
  color: white;
}
.filteredContent.programsFiltered .accordionBottom .checkboxes label {
  width: 100%;
}

.filteredContent .button {
  border-radius: 10px;
  -webkit-box-shadow: #E4EAED 0px 3px;
          box-shadow: #E4EAED 0px 3px;
}
.filteredContent h3 {
  margin-top: 0px;
}
.filteredContent .grid#filteredContentResult {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.filteredContent .grid#filteredContentResult li.col {
  margin: 0;
  padding: 15px 0px 15px 0px;
  border-top: 2px solid #707070;
  display: block;
}
html.k-ie.k-ie11 .filteredContent .grid#filteredContentResult li.col {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.filteredContent .grid#filteredContentResult li.col:first-child {
  margin: 0;
  padding: 15px 0px 15px 0px;
  border-top: none;
}
.filteredContent .grid#filteredContentResult li.col:last-child {
  margin: 0;
  padding: 15px 0px 15px 0px;
  border-bottom: 2px solid #707070;
}
.filteredContent .filtersContainer {
  padding-top: 50px;
  padding-bottom: 50px;
}
.filteredContent .filtersContainer .buttonsContainer {
  margin: 0px;
}
.filteredContent .filtersContainer .buttonsContainer .button {
  margin: 0px;
}
.filteredContent .accordion .accordionTop {
  padding: 5px 55px 5px 20px;
  margin: 5px 5px 5px 5px;
  background-color: #E4EAED;
  position: relative;
}
.filteredContent .accordion .accordionTop::before {
  content: "\f067";
  position: absolute;
  color: #64002E;
  right: 0%;
  margin-right: 25px;
  z-index: 2;
  display: block;
}
.filteredContent .accordion .accordionBottom .grid.checkboxes {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 100%;
  margin: 0;
}
.filteredContent .accordion .accordionBottom .grid.checkboxes .field {
  padding: 5px 20px 5px 20px;
  margin: 5px 5px 5px 100px;
  background-color: #E4EAED;
  display: block;
}
html.k-ie.k-ie11 .filteredContent .accordion .accordionBottom .grid.checkboxes .col {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}

.searchModal {
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-transform: scale(0.9) !important;
  transform: scale(0.9) !important;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  text-align: center !important;
}
.popup_visible .searchModal {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s !important;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: transform 0.4s, opacity 0.4s !important;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s !important;
}
.searchModal .close {
  background: transparent !important;
  margin: 1em auto;
}
.searchModal .formGroup {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.searchModal .formGroup > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 .searchModal .formGroup > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.searchModal .formGroup input[type=search] {
  width: 60vw;
  max-width: 100%;
  color: #64002E;
  padding: 0.2em 1em;
  font-size: 2rem;
  border: none;
  background-repeat: no-repeat;
  background-position: right 1em center;
  text-overflow: ellipsis;
}
.searchModal .formGroup input[type=search]::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.searchModal .formGroup input[type=search]:-moz-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.searchModal .formGroup input[type=search]::-moz-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.searchModal .formGroup input[type=search]:-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.searchModal .formGroup input[type=search]:hover::-webkit-input-placeholder, .searchModal .formGroup input[type=search]:focus::-webkit-input-placeholder {
  opacity: 0.4;
}
.searchModal .formGroup input[type=search]:hover:-moz-placeholder, .searchModal .formGroup input[type=search]:focus:-moz-placeholder {
  opacity: 0.4;
}
.searchModal .formGroup input[type=search]:hover::-moz-placeholder, .searchModal .formGroup input[type=search]:focus::-moz-placeholder {
  opacity: 0.4;
}
.searchModal .formGroup input[type=search]:hover:-ms-input-placeholder, .searchModal .formGroup input[type=search]:focus:-ms-input-placeholder {
  opacity: 0.4;
}
.searchModal .formGroup input[type=search]:focus {
  outline: 2px solid #00557B;
}
.searchModal .formGroup .sfsearchSubmit {
  height: 4rem;
  margin: 0;
  min-width: initial;
  text-transform: none;
  font-weight: 300;
  font-size: 1.2rem;
  margin-left: 0.3rem;
}

.searchModalBackground {
  background-color: rgba(100, 0, 46, 0.87) !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-transform: scale(0.9) !important;
  transform: scale(0.9) !important;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
}
.searchModalBackground.open {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s !important;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: transform 0.4s, opacity 0.4s !important;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s !important;
}

.searchModalBackground {
  background-color: rgba(100, 0, 46, 0.87) !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-transform: scale(0.9) !important;
  transform: scale(0.9) !important;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s !important;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s !important;
}
.searchModalBackground.open {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s !important;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: opacity 0.4s, -webkit-transform 0.4s !important;
  transition: transform 0.4s, opacity 0.4s !important;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s !important;
}

.searchResults > ul.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.searchResults > ul.grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
html.k-ie.k-ie11 .searchResults > ul.grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.searchResults > ul.grid > .col {
  margin-bottom: 1em;
}

div.searchPager {
  margin: 2em 0;
  text-align: center;
}
div.searchPager ul.pagination {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
div.searchPager ul.pagination > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 div.searchPager ul.pagination > li > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
div.searchPager ul.pagination li {
  margin-top: 1em;
}
div.searchPager ul.pagination li.active a {
  color: #1A3053;
}

.aboutUsParagraph {
  margin-top: 100px !important;
}

.aboutUsLinks {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.aboutUsLinks .sfContentBlock.sf-Long-text {
  padding-left: 25px;
  margin-top: 100px !important;
  border-left: 1px solid #64002E;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.aboutUsLinks .sfContentBlock.sf-Long-text ul {
  list-style: none;
}
.aboutUsLinks .sfContentBlock.sf-Long-text ul li {
  list-style: none;
  padding-left: 0px;
  padding-bottom: 10px;
}
.aboutUsLinks .sfContentBlock.sf-Long-text ul li:before {
  content: none;
}
.aboutUsLinks .sfContentBlock.sf-Long-text ul li a {
  text-decoration: none;
  font-size: 30px;
  font-family: "canada-type-gibson" sans-serif;
  color: black;
}
.aboutUsLinks .sfContentBlock.sf-Long-text h4 {
  color: #64002E;
  font-size: 36px;
  font-family: "canada-type-gibson", sans-serif;
  margin-top: 0px;
}

.searchByProvinceHeading {
  width: 100%;
  text-align: center;
  margin-top: 50px !important;
  margin-bottom: 0px !important;
}

.homeProgramSearch.filteredContent {
  padding-top: 100px;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer {
  padding-top: 25px;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .homeMap {
  width: 90%;
  margin: auto;
  min-width: 200px;
  max-width: 800px;
  padding-top: 50px;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordSearch:-ms-input-placeholder, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordSearch:-ms-input-placeholder, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordSearch::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  text-transform: unset;
  opacity: 1; /* Firefox */
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordSearch::-ms-input-placeholder, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordSearch:-ms-input-placeholder, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordSearch::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  text-transform: unset;
  opacity: 1; /* Firefox */
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordSearch::placeholder, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordSearch:-ms-input-placeholder, .homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordSearch::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  text-transform: unset;
  opacity: 1; /* Firefox */
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .grid {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionTop {
  background-color: rgba(228, 234, 237, 0.85);
  border-radius: 10px;
  margin-top: 150px;
  margin-bottom: 50px;
  max-width: 400px;
  font-size: 1.25rem;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionTop label {
  height: 58px;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionTop[data-group=data-jurisidictions-guids]::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  top: -webkit-calc(0% - 100px);
  top: calc(0% - 100px);
  left: -webkit-calc(50% - 100px);
  left: calc(50% - 100px);
  background-size: contain;
  z-index: -1;
  background-image: url("/CFImages/homeLocation.png");
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionTop[data-group=data-assistivetechnologytypes-guids]::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  top: -webkit-calc(0% - 100px);
  top: calc(0% - 100px);
  left: -webkit-calc(50% - 100px);
  left: calc(50% - 100px);
  background-size: contain;
  z-index: -1;
  background-image: url("/CFImages/homePhrase.png");
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionTop.active::before {
  content: "\f068";
  position: absolute;
  color: #64002E;
  right: 0%;
  margin-right: 25px;
  z-index: 2;
  display: block;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionBottom label {
  padding-left: 0px;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionBottom label:before {
  content: none;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionBottom label:after {
  content: none;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionBottom li.field {
  width: -webkit-calc(100% - 105px);
  width: calc(100% - 105px);
  border-radius: 10px;
  opacity: 0.85;
  display: block;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordHome {
  margin-top: 45px;
  margin-bottom: 15px;
  max-width: 400px;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordHome:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordHome .keywordSearch {
  background-color: rgba(228, 234, 237, 0.85);
  border-radius: 10px;
  border-width: 0px;
  height: 58px;
  margin: 5px;
  margin-top: 105px;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordHome label {
  height: 58px;
  font-size: 1.2rem;
}
.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .keywordHome .keywordSearchBackground {
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 3;
  background-image: url("/CFImages/homeKeyword.png");
}

.purpleSection {
  background-color: rgba(252, 215, 254, 0.7);
  width: 100%;
  padding-bottom: 25px;
}

.yellowSection {
  background-color: rgba(253, 255, 223, 0.4);
  width: 100%;
  padding-bottom: 25px;
}

.aboutUsDescription {
  padding-top: 100px;
}

.blueSection {
  background-color: rgba(0, 196, 255, 0.2);
  width: 100%;
  padding-bottom: 25px;
}

.filteredContent legend {
  position: fixed;
  left: -9999px;
}

.homeProgramSearch.filteredContent .filtersContainer.innerContainer .keywordSearchContainer .accordion .accordionBottom label:hover {
  color: rgb(0, 196, 255) !important;
}

.ourFundersBanner .twoToneBanner .topSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  min-height: 350px;
  background-color: rgba(211, 254, 219, 0.7);
  width: 100%;
}
.k-ie11 .ourFundersBanner .twoToneBanner .topSection {
  height: 350px;
}
.ourFundersBanner .twoToneBanner .bottomSection {
  min-height: 316px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 196, 255, 0.2);
  width: 100%;
}
.k-ie11 .ourFundersBanner .twoToneBanner .bottomSection {
  height: 316px;
}
.ourFundersBanner .twoToneBanner .titleContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.ourFundersBanner .twoToneBanner .titleContent .bannerTitle {
  padding-right: 40%;
}

.ourFundersContent {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ourPartnersContent {
  padding-top: 50px;
  padding-bottom: 50px;
}

#advancedSearchButton {
  text-decoration: underline;
  float: right;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  background-color: rgba(228, 234, 237, 0.85);
  margin-right: -5px;
}

.ourPartnersBanner .twoToneBanner .topSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  min-height: 300px;
  background-color: rgba(211, 254, 219, 0.7);
  width: 100%;
}
.k-ie11 .ourPartnersBanner .twoToneBanner .topSection {
  height: 300px;
}
.ourPartnersBanner .twoToneBanner .bottomSection {
  min-height: 300px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(252, 215, 254, 0.7);
  width: 100%;
}
.k-ie11 .ourPartnersBanner .twoToneBanner .bottomSection {
  height: 300px;
}
.ourPartnersBanner .twoToneBanner .titleContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.ourPartnersBanner .twoToneBanner .titleContent .bannerTitle {
  padding-right: 40%;
}

.pageBanner.homeBanner {
  min-height: 500px;
  height: 500px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.k-ie11 .pageBanner.homeBanner {
  height: 500px;
}
.pageBanner.homeBanner .bannerTitle {
  padding-bottom: 50px;
  max-width: 600px;
}
.pageBanner.homeBanner .bannerImage.twoToneBanner .bgImg {
  margin-right: 0px;
  background-position: top left !important;
}
.pageBanner.homeBanner .bannerImage.twoToneBanner .bgImg:last-child {
  background-position: bottom left;
}

.programsBanner .twoToneBanner .topSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  min-height: 350px;
  background-color: rgba(211, 254, 219, 0.7);
  width: 100%;
}
.k-ie11 .programsBanner .twoToneBanner .topSection {
  height: 350px;
}
.programsBanner .twoToneBanner .bottomSection {
  min-height: 316px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 196, 255, 0.2);
  width: 100%;
}
.k-ie11 .programsBanner .twoToneBanner .bottomSection {
  height: 316px;
}
.programsBanner .twoToneBanner .titleContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.programsBanner .twoToneBanner .titleContent .bannerTitle {
  padding-right: 40%;
}

.resourcesBanner .twoToneBanner .topSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  min-height: 300px;
  background-color: rgba(252, 215, 254, 0.7);
  width: 100%;
}
.k-ie11 .resourcesBanner .twoToneBanner .topSection {
  height: 300px;
}
.resourcesBanner .twoToneBanner .bottomSection {
  min-height: 300px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 196, 255, 0.2);
  width: 100%;
}
.k-ie11 .resourcesBanner .twoToneBanner .bottomSection {
  height: 300px;
}
.resourcesBanner .twoToneBanner .titleContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.resourcesBanner .twoToneBanner .titleContent .bannerTitle {
  padding-right: 40%;
}

html.k-ie.k-ie11 .programsBanner .twoToneBanner .bottomSection {
  min-height: 240px;
  height: 240px;
}

.programDetailBanner .twoToneBanner .topSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  min-height: 300px;
  background-color: rgba(253, 255, 223, 0.4);
  width: 100%;
  padding: 25px 0px 25px 0px;
}
.k-ie11 .programDetailBanner .twoToneBanner .topSection {
  height: 300px;
}
.programDetailBanner .twoToneBanner .bottomSection {
  min-height: 316px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 196, 255, 0.2);
  width: 100%;
}
.k-ie11 .programDetailBanner .twoToneBanner .bottomSection {
  height: 316px;
}
.programDetailBanner .twoToneBanner .titleContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.programDetailBanner .twoToneBanner .titleContent .bannerTitle {
  padding-right: 40%;
}
.programDetailBanner .twoToneBanner .titleContent .bannerTitle h1 {
  font-size: 30px;
}

.teamBanner .twoToneBanner .topSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  min-height: 300px;
  background-color: rgba(252, 215, 254, 0.7);
  width: 100%;
}
.k-ie11 .teamBanner .twoToneBanner .topSection {
  height: 300px;
}
.teamBanner .twoToneBanner .bottomSection {
  min-height: 300px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 196, 255, 0.2);
  width: 100%;
}
.k-ie11 .teamBanner .twoToneBanner .bottomSection {
  height: 300px;
}
.teamBanner .twoToneBanner .titleContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.teamBanner .twoToneBanner .titleContent .bannerTitle {
  padding-right: 40%;
}

.bannerSubtitle {
  padding-right: 40%;
  z-index: 2;
  line-height: 1em;
  color: #1A3053;
}
.bannerSubtitle p {
  font-size: 35px;
  margin-bottom: 0px;
  font-family: "canada-type-gibson", sans-serif;
}

.programsDetailBanner .twoToneBanner .yellowSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  min-height: 300px;
}
.k-ie11 .programsDetailBanner .twoToneBanner .yellowSection {
  height: 300px;
}
.programsDetailBanner .twoToneBanner .blueSection {
  min-height: 300px;
}
.k-ie11 .programsDetailBanner .twoToneBanner .blueSection {
  height: 300px;
}
.programsDetailBanner .twoToneBanner .titleContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.programsDetailBanner .twoToneBanner .titleContent .bannerTitle {
  padding-right: 40%;
}
.programsDetailBanner .twoToneBanner .programsDetailBannerAbout {
  padding-right: 40%;
  z-index: 2;
  padding-bottom: 25px;
  line-height: 1em;
  color: #1A3053;
}
.programsDetailBanner .twoToneBanner .programsDetailBannerAbout p {
  font-size: 40px;
  font-family: "canada-type-gibson" sans-serif;
}

ol.breadcrumb {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
ol.breadcrumb .active a {
  color: #1A3053;
}

.province:hover {
  cursor: pointer;
}

.programDetailMainContent {
  padding-top: 100px;
  padding-bottom: 100px;
}
.programDetailMainContent .backButton {
  margin: 0;
  margin-top: 25px;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  width: 260px;
  letter-spacing: 0.2em;
}
.programDetailMainContent .col.relatedProgramsDiv {
  margin-left: 80px;
  padding-left: 10px;
  border-left: 1px solid #64002E;
}
.programDetailMainContent .col.relatedProgramsDiv .relatedProgram {
  padding: 20px 0px 20px 0px;
  line-height: 1.5em;
}
.programDetailMainContent .col.relatedProgramsDiv h3 {
  color: #64002E;
  margin-left: 10px;
}
.programDetailMainContent .col.relatedProgramsDiv ul {
  list-style: none;
}
.programDetailMainContent .col.relatedProgramsDiv ul li {
  list-style: none;
  border-top: 2px solid #707070;
  margin-left: 10px;
}
.programDetailMainContent .col.relatedProgramsDiv ul li:last-child {
  border-bottom: 2px solid #707070;
}
.programDetailMainContent .col.relatedProgramsDiv ul ::before {
  content: none;
}
.programDetailMainContent .typeIcons {
  padding: 25px 25px 0px 25px;
  display: inline-block;
}
.programDetailMainContent .typeIcons .typeIcon {
  max-width: 95px;
  max-height: 95px;
  padding: 5px;
}
.programDetailMainContent ul {
  margin-left: 1em;
}
.programDetailMainContent ul li::before {
  content: "•"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #64002E; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.programDetailMainContent ul ul {
  margin-left: 0em;
}
.programDetailMainContent .programUrl ul li::before {
  content: none;
}
.programDetailMainContent .programUrl ul {
  margin-left: 0em;
}
.programDetailMainContent .programUrl ul li {
  border-top: 2px solid grey;
  border-bottom: 2px solid grey;
  padding: 20px 0px 20px 0px;
}
.programDetailMainContent .grid {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.breadcrumbsSpan {
  padding-bottom: 50px;
}

.pageBanner.bioBanner .topSection {
  padding-top: 0px;
}

.pageBanner.contactBanner .topSection {
  background-color: rgba(0, 196, 255, 0.2);
  min-height: 300px;
}
.k-ie11 .pageBanner.contactBanner .topSection {
  height: 300px;
}
.pageBanner.contactBanner .bottomSection {
  background-color: rgba(253, 255, 223, 0.4);
  min-height: 300px;
}
.k-ie11 .pageBanner.contactBanner .bottomSection {
  height: 300px;
}

.pageBanner {
  color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*padding: 2em;*/
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.pageBanner .bannerImage .bgImg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events: none;
}
.pageBanner .bannerImage .bgImg:after {
  position: relative;
  display: block;
  height: 100%;
  content: " ";
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(100, 0, 46, 0.8)), to(rgba(100, 0, 46, 0.2)));
  background-image: linear-gradient(to right, rgba(100, 0, 46, 0.8), rgba(100, 0, 46, 0.2) 100%);
}
.pageBanner .bannerImage.twoToneBanner .topSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.pageBanner .bannerImage.twoToneBanner .bottomSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.pageBanner .bannerImage.twoToneBanner .titleContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.pageBanner .bannerImage.twoToneBanner .breadcrumbsSpan {
  color: #707070;
}
.pageBanner .bannerImage.twoToneBanner .breadcrumbsSpan a {
  color: #707070;
}
.pageBanner .bannerImage.twoToneBanner .breadcrumbsSpan a.activeCrumb {
  color: #1A3053;
}
.pageBanner .bannerImage.twoToneBanner .bgImg {
  pointer-events: none;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: unset;
  margin-right: 100px;
}
.pageBanner .bannerImage.twoToneBanner .bgImg:after {
  position: relative;
  display: block;
  content: " ";
  background-image: none;
}
.pageBanner .bannerImage .slick-slider {
  width: 100%;
  height: 100%;
  position: relative;
}
.pageBanner .bannerImage .slick-slider .slick-list {
  width: 100%;
  height: 100%;
  position: relative;
}
.pageBanner .bannerImage .slick-slider .slick-list .slick-track {
  width: 100%;
  height: 100%;
  position: relative;
}
.pageBanner .bannerImage .slick-slider .slide {
  width: 100%;
  height: 100%;
  position: relative;
}
.pageBanner .bannerTitle {
  display: inline-block;
  position: relative;
  z-index: 2;
}
.pageBanner .bannerTitle h1 {
  color: #08263D;
}

/* Page Title (Sometimes in banner sometimes in header or body) */
span.pseudoH1 {
  font-family: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 700;
  margin: 1em 0 0.35em 0;
  color: #fff;
  font-size: 4em;
  line-height: 1.25em;
}

.sf-Long-text,
.cmsContent {
  color: #08263D;
  font-size: 1em;
  line-height: 1.4em;
  /* Headings */
  /* Links */
  /* Paragraphs */
  /* Unordered Lists */
  /* Ordered Lists */
  /* Special lists:   Alpha & Roman Numerals */
  /* Misc content elements */
}
.sf-Long-text img,
.cmsContent img {
  max-width: 100%;
}
.sf-Long-text em, .sf-Long-text i,
.cmsContent em,
.cmsContent i {
  font-style: italic;
}
.sf-Long-text h1,
.cmsContent h1 {
  color: inherit;
}
.sf-Long-text a:not(.button),
.cmsContent a:not(.button) {
  color: #1A3053;
}
.sf-Long-text a:not(.button):hover,
.cmsContent a:not(.button):hover {
  color: #00557B;
}
.sf-Long-text p,
.cmsContent p {
  line-height: 1.4em;
}
.sf-Long-text ul:not(.reset),
.cmsContent ul:not(.reset) {
  list-style-type: none;
  margin: 1em 0;
}
.sf-Long-text ul:not(.reset) > li,
.cmsContent ul:not(.reset) > li {
  list-style-type: none;
  margin: 0;
  padding: 0.2em 0;
  padding-left: 1.75em;
  position: relative;
  font-size: 1em;
}
.sf-Long-text ul:not(.reset) > li:before,
.cmsContent ul:not(.reset) > li:before {
  top: 2.5em;
  left: 3em;
  color: #1A3053;
  content: "\f111";
  display: inline-block;
  position: absolute;
  font-size: 0.3em;
  line-height: 1em;
}
.sf-Long-text ul:not(.reset) > li ul,
.cmsContent ul:not(.reset) > li ul {
  margin: 0;
  margin-top: 0.5em;
}
.sf-Long-text ul:not(.reset) > li ol,
.cmsContent ul:not(.reset) > li ol {
  margin: 0;
  margin-top: 0.5em;
}
.sf-Long-text ul:not(.reset) > li ol > li,
.cmsContent ul:not(.reset) > li ol > li {
  /*list-style-type: decimal;
  list-style-position: inside;
  background: none;
  margin: 0;
  padding: 0.2em 0;*/
}
.sf-Long-text ol:not(.reset),
.cmsContent ol:not(.reset) {
  margin: 1em 0;
  counter-reset: customListCounter 0;
  list-style-position: outside;
}
.sf-Long-text ol:not(.reset) > li,
.cmsContent ol:not(.reset) > li {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  position: relative;
  padding: 0.2em 0;
  padding-left: 1.75em;
  counter-increment: customListCounter;
}
.sf-Long-text ol:not(.reset) > li:before,
.cmsContent ol:not(.reset) > li:before {
  top: 0.2em;
  left: 0.4em;
  content: counter(customListCounter) ".";
  color: #00557B;
  position: absolute;
  font-weight: 700;
}
.sf-Long-text ol:not(.reset) > li ol,
.cmsContent ol:not(.reset) > li ol {
  margin: 0;
  margin-top: 0.5em;
}
.sf-Long-text ol:not(.reset) > li ol > li,
.cmsContent ol:not(.reset) > li ol > li {
  background: none;
  margin: 0;
}
.sf-Long-text ol:not(.reset) > li ol > li:before,
.cmsContent ol:not(.reset) > li ol > li:before {
  color: #64002E;
}
.sf-Long-text ol:not(.reset) > li ul,
.cmsContent ol:not(.reset) > li ul {
  margin: 0;
  margin-top: 0.5em;
}
.sf-Long-text ol:not(.reset) > li ul > li,
.cmsContent ol:not(.reset) > li ul > li {
  /*list-style-type: none;
  list-style-position: inside;*/
}
.sf-Long-text ul:not(.reset).roman > li,
.sf-Long-text ol:not(.reset).roman > li,
.cmsContent ul:not(.reset).roman > li,
.cmsContent ol:not(.reset).roman > li {
  padding-left: 2em;
}
.sf-Long-text ul:not(.reset).roman > li:before,
.sf-Long-text ol:not(.reset).roman > li:before,
.cmsContent ul:not(.reset).roman > li:before,
.cmsContent ol:not(.reset).roman > li:before {
  content: counter(customListCounter, lower-roman) ".";
}
.sf-Long-text ul:not(.reset).alpha > li:before, .sf-Long-text ul:not(.reset).lettered > li:before,
.sf-Long-text ol:not(.reset).alpha > li:before,
.sf-Long-text ol:not(.reset).lettered > li:before,
.cmsContent ul:not(.reset).alpha > li:before,
.cmsContent ul:not(.reset).lettered > li:before,
.cmsContent ol:not(.reset).alpha > li:before,
.cmsContent ol:not(.reset).lettered > li:before {
  content: counter(customListCounter, lower-alpha) ".";
}
.sf-Long-text ul:not(.reset).upper > li:before, .sf-Long-text ul:not(.reset).uppercase > li:before,
.sf-Long-text ol:not(.reset).upper > li:before,
.sf-Long-text ol:not(.reset).uppercase > li:before,
.cmsContent ul:not(.reset).upper > li:before,
.cmsContent ul:not(.reset).uppercase > li:before,
.cmsContent ol:not(.reset).upper > li:before,
.cmsContent ol:not(.reset).uppercase > li:before {
  text-transform: uppercase;
}
.sf-Long-text address,
.cmsContent address {
  margin: 0.5em 0 1em;
}
.sf-Long-text mark,
.cmsContent mark {
  background-color: #00D3FF;
}

main {
  margin: 0 auto;
  /*padding-top: $totalHeaderHeight;*/
}
main .bd .breadCrumb .breadCrumbInner {
  padding: 5px 0;
  font-size: 12px;
}
main .bd .leftContent > .sf_colsIn {
  padding-right: 2em;
}
main .bd .mainContent .sfContentBlock img {
  max-width: 100%;
}

footer {
  position: relative;
  z-index: 1;
}
footer a {
  color: white;
  text-decoration: underline !important;
}
footer img {
  max-width: 400px !important;
  width: 100%;
}
footer .preFooter {
  display: none;
}
footer .mainFooter {
  font-size: 0.6em;
  min-height: 500px;
  color: #ffffff;
}
footer .mainFooter .rightLogo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
footer .mainFooter .rightLogo, footer .mainFooter .leftLogo {
  padding-top: 50px;
}
footer .mainFooter .errorFormContainer {
  padding-top: 50px;
  margin-bottom: 100px;
  max-width: 500px;
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}
footer .mainFooter .errorFormContainer h3 {
  color: #1A3053;
}
footer .mainFooter .errorFormContainer textarea {
  height: 100px;
  margin-top: 20px;
  font-size: 20px;
}
footer .mainFooter .errorFormContainer label {
  margin: 0;
  padding: 0;
  color: #64002E;
  font-size: 1.5em;
}
footer .mainFooter .errorFormContainer input[type=email] {
  max-height: 60px;
  font-size: 20px;
}
footer .mainFooter .errorFormContainer button {
  border-radius: 10px;
  font-size: 20px;
}
footer .mainFooter .greyGradientFooter {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1%, #ebebeb), color-stop(40%, white));
  background-image: linear-gradient(to top, #ebebeb 1%, white 40%);
}
footer .mainFooter .greyGradientFooter .collaborationFooter {
  padding-top: 20px;
  display: block;
  min-height: 325px;
}
footer .mainFooter .greyGradientFooter .collaborationFooter h4 {
  width: 100%;
  font-weight: normal;
  font-family: "canada-type-gibson", sans-serif;
  font-size: 30px;
  color: #1A3053;
  height: 30px;
  margin: 15px 0px 55px 0px;
}
footer .mainFooter .greyGradientFooter .collaborationFooter .footerWrapperCollaboration {
  min-height: 295px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
footer .mainFooter .greyGradientFooter .collaborationFooter .footerWrapperCollaboration .sf_colsIn {
  display: block;
  width: 100%;
}
footer .mainFooter .greyGradientFooter .collaborationFooter .footerWrapperCollaboration .sf_colsIn .grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-bottom: 50px;
}
footer .mainFooter .greyGradientFooter .collaborationFooter .footerWrapperCollaboration .sf_colsIn .grid .toronto {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
footer .mainFooter .greyGradientFooter .collaborationFooter .footerWrapperCollaboration .sf_colsIn .grid .mcmaster {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
footer .mainFooter .greyGradientFooter .collaborationFooter .footerWrapperCollaboration .sf_colsIn .grid .dimes {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
footer .mainFooter .accessFooter {
  min-height: 240px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
footer .mainFooter .accessFooter > .grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
footer .mainFooter .accessFooter > .grid > div {
  padding-bottom: 55px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
footer .mainFooter .accessFooter > .grid > div:nth-child(1) {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
footer .mainFooter .accessFooter > .grid > div:nth-child(2) {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
footer .mainFooter h3, footer .mainFooter p, footer .mainFooter p a, footer .mainFooter a {
  color: #ffffff;
}
footer .mainFooter .footerAbout {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #fff;
}
footer .mainFooter .footerAbout p {
  margin-bottom: 0;
}
footer .mainFooter .footerCopyright {
  padding: 1.5em 0 0 0;
}
footer .mainFooter .footerCopyright p {
  margin: 0;
}
footer .cubicle {
  padding: 1em 0;
  font-size: 16px;
  font-family: "canada-type-gibson" sans-serif;
  display: block;
  color: white;
  text-align: center;
  background-color: #64002E;
}
footer .cubicle a:link, footer .cubicle a:visited {
  text-decoration: none;
  color: #fff;
}
footer .cubicle p {
  color: white;
}

/*#endregion LAYOUT SECTION*/
/*#region SOCIAL LINKS AND ICONS*/
ul.socialLinks.reset {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  font-size: 1.2em;
  font-family: "Font Awesome 5 Pro";
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  color: #1A3053;
}
ul.socialLinks.reset > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 ul.socialLinks.reset > li > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
ul.socialLinks.reset li {
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  color: #1A3053;
}
ul.socialLinks.reset li a {
  color: #1A3053;
  width: auto;
  height: auto;
  line-height: 1em;
  padding: 0;
  position: relative;
  font-size: 0.6em;
  margin-left: 25px;
  font-weight: bold;
  font-family: "canada-type-gibson" sans-serif;
  text-decoration: none !important;
}
ul.socialLinks.reset li a:before {
  font-size: 1em;
  margin-right: 0.1em;
}
ul.socialLinks.reset li a:hover, ul.socialLinks.reset li a:focus ul.socialLinks.reset li a:active {
  color: #64002E;
  text-decoration: none;
}
ul.socialLinks.reset li a em {
  font-size: 1.5em;
  margin-right: 10px;
}

.contactOffices .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.contactOffices .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
          flex-basis: calc((1 / 2) * 100% - 1rem);
}
html.k-ie.k-ie11 .contactOffices .grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}

ul.sharingLinks {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
ul.sharingLinks > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 ul.sharingLinks > li > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
ul.sharingLinks li.sharingLink button {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  background-image: none;
  color: #1A3053;
  font-size: 1.8rem;
}
ul.sharingLinks li.sharingLink button i {
  color: inherit;
}
ul.sharingLinks li.sharingLink button:hover, ul.sharingLinks li.sharingLink button:focus ul.sharingLinks li.sharingLink button:active {
  color: #00557B;
}

div.socialAndSharing {
  padding: 2em 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
div.socialAndSharing > ul {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 div.socialAndSharing > ul > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
div.socialAndSharing ul.socialLinks {
  font-size: 1.8rem;
}
div.socialAndSharing ul.sharingLinks {
  font-size: 1.8rem;
}

/*#endregion SocialLinks SECTION*/
/*#region FORMS */
.pageWrapper label,
footer label,
.popup_wrapper label {
  cursor: pointer;
}
.pageWrapper fieldset,
footer fieldset,
.popup_wrapper fieldset {
  padding: 0;
  border: none;
}
.pageWrapper input[type=checkbox],
footer input[type=checkbox],
.popup_wrapper input[type=checkbox] {
  cursor: pointer;
  width: 1px;
  height: 1px;
  position: absolute;
  visibility: hidden;
}
.pageWrapper input[type=checkbox] + label,
footer input[type=checkbox] + label,
.popup_wrapper input[type=checkbox] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 2em;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.pageWrapper input[type=checkbox] + label::-moz-selection,
footer input[type=checkbox] + label::-moz-selection,
.popup_wrapper input[type=checkbox] + label::-moz-selection {
  background-color: transparent;
}
.pageWrapper input[type=checkbox] + label::selection,
footer input[type=checkbox] + label::selection,
.popup_wrapper input[type=checkbox] + label::selection {
  background-color: transparent;
}
.pageWrapper input[type=checkbox] + label:hover,
footer input[type=checkbox] + label:hover,
.popup_wrapper input[type=checkbox] + label:hover {
  color: #1A3053;
}
.pageWrapper input[type=checkbox] + label:before, .pageWrapper input[type=checkbox] + label:after,
footer input[type=checkbox] + label:before,
footer input[type=checkbox] + label:after,
.popup_wrapper input[type=checkbox] + label:before,
.popup_wrapper input[type=checkbox] + label:after {
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  font-size: 1rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-right: 0.4em;
  font-family: "Font Awesome 5 Pro";
}
.pageWrapper input[type=checkbox] + label:before,
footer input[type=checkbox] + label:before,
.popup_wrapper input[type=checkbox] + label:before {
  content: "\f0c8";
  font-weight: 900;
  color: white;
}
.pageWrapper input[type=checkbox] + label:after,
footer input[type=checkbox] + label:after,
.popup_wrapper input[type=checkbox] + label:after {
  content: "\f0c8";
  font-weight: 300;
  color: #1A3053;
}
.pageWrapper input[type=checkbox]:checked + label:after,
footer input[type=checkbox]:checked + label:after,
.popup_wrapper input[type=checkbox]:checked + label:after {
  content: "\f2d3";
}
.pageWrapper input[type=text], .pageWrapper input[type=email], .pageWrapper input[type=search], .pageWrapper input[type=number], .pageWrapper input[type=password],
footer input[type=text],
footer input[type=email],
footer input[type=search],
footer input[type=number],
footer input[type=password],
.popup_wrapper input[type=text],
.popup_wrapper input[type=email],
.popup_wrapper input[type=search],
.popup_wrapper input[type=number],
.popup_wrapper input[type=password] {
  width: 100%;
  /*max-width: 280px;*/
  color: #64002E;
  padding: 0.2em 1em;
  padding-right: 3em;
  height: 62.39px;
  border: 1px solid #1A3053;
  text-overflow: ellipsis;
}
.pageWrapper input[type=text]::-webkit-input-placeholder, .pageWrapper input[type=email]::-webkit-input-placeholder, .pageWrapper input[type=search]::-webkit-input-placeholder, .pageWrapper input[type=number]::-webkit-input-placeholder, .pageWrapper input[type=password]::-webkit-input-placeholder,
footer input[type=text]::-webkit-input-placeholder,
footer input[type=email]::-webkit-input-placeholder,
footer input[type=search]::-webkit-input-placeholder,
footer input[type=number]::-webkit-input-placeholder,
footer input[type=password]::-webkit-input-placeholder,
.popup_wrapper input[type=text]::-webkit-input-placeholder,
.popup_wrapper input[type=email]::-webkit-input-placeholder,
.popup_wrapper input[type=search]::-webkit-input-placeholder,
.popup_wrapper input[type=number]::-webkit-input-placeholder,
.popup_wrapper input[type=password]::-webkit-input-placeholder {
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-transform: none;
}
.pageWrapper input[type=text]:-moz-placeholder, .pageWrapper input[type=email]:-moz-placeholder, .pageWrapper input[type=search]:-moz-placeholder, .pageWrapper input[type=number]:-moz-placeholder, .pageWrapper input[type=password]:-moz-placeholder,
footer input[type=text]:-moz-placeholder,
footer input[type=email]:-moz-placeholder,
footer input[type=search]:-moz-placeholder,
footer input[type=number]:-moz-placeholder,
footer input[type=password]:-moz-placeholder,
.popup_wrapper input[type=text]:-moz-placeholder,
.popup_wrapper input[type=email]:-moz-placeholder,
.popup_wrapper input[type=search]:-moz-placeholder,
.popup_wrapper input[type=number]:-moz-placeholder,
.popup_wrapper input[type=password]:-moz-placeholder {
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-transform: none;
}
.pageWrapper input[type=text]::-moz-placeholder, .pageWrapper input[type=email]::-moz-placeholder, .pageWrapper input[type=search]::-moz-placeholder, .pageWrapper input[type=number]::-moz-placeholder, .pageWrapper input[type=password]::-moz-placeholder,
footer input[type=text]::-moz-placeholder,
footer input[type=email]::-moz-placeholder,
footer input[type=search]::-moz-placeholder,
footer input[type=number]::-moz-placeholder,
footer input[type=password]::-moz-placeholder,
.popup_wrapper input[type=text]::-moz-placeholder,
.popup_wrapper input[type=email]::-moz-placeholder,
.popup_wrapper input[type=search]::-moz-placeholder,
.popup_wrapper input[type=number]::-moz-placeholder,
.popup_wrapper input[type=password]::-moz-placeholder {
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-transform: none;
}
.pageWrapper input[type=text]:-ms-input-placeholder, .pageWrapper input[type=email]:-ms-input-placeholder, .pageWrapper input[type=search]:-ms-input-placeholder, .pageWrapper input[type=number]:-ms-input-placeholder, .pageWrapper input[type=password]:-ms-input-placeholder,
footer input[type=text]:-ms-input-placeholder,
footer input[type=email]:-ms-input-placeholder,
footer input[type=search]:-ms-input-placeholder,
footer input[type=number]:-ms-input-placeholder,
footer input[type=password]:-ms-input-placeholder,
.popup_wrapper input[type=text]:-ms-input-placeholder,
.popup_wrapper input[type=email]:-ms-input-placeholder,
.popup_wrapper input[type=search]:-ms-input-placeholder,
.popup_wrapper input[type=number]:-ms-input-placeholder,
.popup_wrapper input[type=password]:-ms-input-placeholder {
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-transform: none;
}
.pageWrapper input[type=text]:hover::-webkit-input-placeholder, .pageWrapper input[type=text]:focus::-webkit-input-placeholder, .pageWrapper input[type=email]:hover::-webkit-input-placeholder, .pageWrapper input[type=email]:focus::-webkit-input-placeholder, .pageWrapper input[type=search]:hover::-webkit-input-placeholder, .pageWrapper input[type=search]:focus::-webkit-input-placeholder, .pageWrapper input[type=number]:hover::-webkit-input-placeholder, .pageWrapper input[type=number]:focus::-webkit-input-placeholder, .pageWrapper input[type=password]:hover::-webkit-input-placeholder, .pageWrapper input[type=password]:focus::-webkit-input-placeholder,
footer input[type=text]:hover::-webkit-input-placeholder,
footer input[type=text]:focus::-webkit-input-placeholder,
footer input[type=email]:hover::-webkit-input-placeholder,
footer input[type=email]:focus::-webkit-input-placeholder,
footer input[type=search]:hover::-webkit-input-placeholder,
footer input[type=search]:focus::-webkit-input-placeholder,
footer input[type=number]:hover::-webkit-input-placeholder,
footer input[type=number]:focus::-webkit-input-placeholder,
footer input[type=password]:hover::-webkit-input-placeholder,
footer input[type=password]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=text]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=text]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=email]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=email]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=search]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=search]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=number]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=number]:focus::-webkit-input-placeholder,
.popup_wrapper input[type=password]:hover::-webkit-input-placeholder,
.popup_wrapper input[type=password]:focus::-webkit-input-placeholder {
  opacity: 0.4;
}
.pageWrapper input[type=text]:hover:-moz-placeholder, .pageWrapper input[type=text]:focus:-moz-placeholder, .pageWrapper input[type=email]:hover:-moz-placeholder, .pageWrapper input[type=email]:focus:-moz-placeholder, .pageWrapper input[type=search]:hover:-moz-placeholder, .pageWrapper input[type=search]:focus:-moz-placeholder, .pageWrapper input[type=number]:hover:-moz-placeholder, .pageWrapper input[type=number]:focus:-moz-placeholder, .pageWrapper input[type=password]:hover:-moz-placeholder, .pageWrapper input[type=password]:focus:-moz-placeholder,
footer input[type=text]:hover:-moz-placeholder,
footer input[type=text]:focus:-moz-placeholder,
footer input[type=email]:hover:-moz-placeholder,
footer input[type=email]:focus:-moz-placeholder,
footer input[type=search]:hover:-moz-placeholder,
footer input[type=search]:focus:-moz-placeholder,
footer input[type=number]:hover:-moz-placeholder,
footer input[type=number]:focus:-moz-placeholder,
footer input[type=password]:hover:-moz-placeholder,
footer input[type=password]:focus:-moz-placeholder,
.popup_wrapper input[type=text]:hover:-moz-placeholder,
.popup_wrapper input[type=text]:focus:-moz-placeholder,
.popup_wrapper input[type=email]:hover:-moz-placeholder,
.popup_wrapper input[type=email]:focus:-moz-placeholder,
.popup_wrapper input[type=search]:hover:-moz-placeholder,
.popup_wrapper input[type=search]:focus:-moz-placeholder,
.popup_wrapper input[type=number]:hover:-moz-placeholder,
.popup_wrapper input[type=number]:focus:-moz-placeholder,
.popup_wrapper input[type=password]:hover:-moz-placeholder,
.popup_wrapper input[type=password]:focus:-moz-placeholder {
  opacity: 0.4;
}
.pageWrapper input[type=text]:hover::-moz-placeholder, .pageWrapper input[type=text]:focus::-moz-placeholder, .pageWrapper input[type=email]:hover::-moz-placeholder, .pageWrapper input[type=email]:focus::-moz-placeholder, .pageWrapper input[type=search]:hover::-moz-placeholder, .pageWrapper input[type=search]:focus::-moz-placeholder, .pageWrapper input[type=number]:hover::-moz-placeholder, .pageWrapper input[type=number]:focus::-moz-placeholder, .pageWrapper input[type=password]:hover::-moz-placeholder, .pageWrapper input[type=password]:focus::-moz-placeholder,
footer input[type=text]:hover::-moz-placeholder,
footer input[type=text]:focus::-moz-placeholder,
footer input[type=email]:hover::-moz-placeholder,
footer input[type=email]:focus::-moz-placeholder,
footer input[type=search]:hover::-moz-placeholder,
footer input[type=search]:focus::-moz-placeholder,
footer input[type=number]:hover::-moz-placeholder,
footer input[type=number]:focus::-moz-placeholder,
footer input[type=password]:hover::-moz-placeholder,
footer input[type=password]:focus::-moz-placeholder,
.popup_wrapper input[type=text]:hover::-moz-placeholder,
.popup_wrapper input[type=text]:focus::-moz-placeholder,
.popup_wrapper input[type=email]:hover::-moz-placeholder,
.popup_wrapper input[type=email]:focus::-moz-placeholder,
.popup_wrapper input[type=search]:hover::-moz-placeholder,
.popup_wrapper input[type=search]:focus::-moz-placeholder,
.popup_wrapper input[type=number]:hover::-moz-placeholder,
.popup_wrapper input[type=number]:focus::-moz-placeholder,
.popup_wrapper input[type=password]:hover::-moz-placeholder,
.popup_wrapper input[type=password]:focus::-moz-placeholder {
  opacity: 0.4;
}
.pageWrapper input[type=text]:hover:-ms-input-placeholder, .pageWrapper input[type=text]:focus:-ms-input-placeholder, .pageWrapper input[type=email]:hover:-ms-input-placeholder, .pageWrapper input[type=email]:focus:-ms-input-placeholder, .pageWrapper input[type=search]:hover:-ms-input-placeholder, .pageWrapper input[type=search]:focus:-ms-input-placeholder, .pageWrapper input[type=number]:hover:-ms-input-placeholder, .pageWrapper input[type=number]:focus:-ms-input-placeholder, .pageWrapper input[type=password]:hover:-ms-input-placeholder, .pageWrapper input[type=password]:focus:-ms-input-placeholder,
footer input[type=text]:hover:-ms-input-placeholder,
footer input[type=text]:focus:-ms-input-placeholder,
footer input[type=email]:hover:-ms-input-placeholder,
footer input[type=email]:focus:-ms-input-placeholder,
footer input[type=search]:hover:-ms-input-placeholder,
footer input[type=search]:focus:-ms-input-placeholder,
footer input[type=number]:hover:-ms-input-placeholder,
footer input[type=number]:focus:-ms-input-placeholder,
footer input[type=password]:hover:-ms-input-placeholder,
footer input[type=password]:focus:-ms-input-placeholder,
.popup_wrapper input[type=text]:hover:-ms-input-placeholder,
.popup_wrapper input[type=text]:focus:-ms-input-placeholder,
.popup_wrapper input[type=email]:hover:-ms-input-placeholder,
.popup_wrapper input[type=email]:focus:-ms-input-placeholder,
.popup_wrapper input[type=search]:hover:-ms-input-placeholder,
.popup_wrapper input[type=search]:focus:-ms-input-placeholder,
.popup_wrapper input[type=number]:hover:-ms-input-placeholder,
.popup_wrapper input[type=number]:focus:-ms-input-placeholder,
.popup_wrapper input[type=password]:hover:-ms-input-placeholder,
.popup_wrapper input[type=password]:focus:-ms-input-placeholder {
  opacity: 0.4;
}
.pageWrapper input[type=text]:focus, .pageWrapper input[type=email]:focus, .pageWrapper input[type=search]:focus, .pageWrapper input[type=number]:focus, .pageWrapper input[type=password]:focus,
footer input[type=text]:focus,
footer input[type=email]:focus,
footer input[type=search]:focus,
footer input[type=number]:focus,
footer input[type=password]:focus,
.popup_wrapper input[type=text]:focus,
.popup_wrapper input[type=email]:focus,
.popup_wrapper input[type=search]:focus,
.popup_wrapper input[type=number]:focus,
.popup_wrapper input[type=password]:focus {
  outline: 2px solid #64002E;
}
.pageWrapper input[type=search],
footer input[type=search],
.popup_wrapper input[type=search] {
  background-image: url(/CFImages/Icons/search-pipe.svg);
  background-repeat: no-repeat;
  background-position: right 1em center;
}
.pageWrapper textarea,
footer textarea,
.popup_wrapper textarea {
  width: 100%;
  display: block;
  min-height: 2.6em;
  border: 1px solid #64002E;
  padding: 1em 1em;
  padding-right: 3em;
}
.pageWrapper textarea::-webkit-input-placeholder,
footer textarea::-webkit-input-placeholder,
.popup_wrapper textarea::-webkit-input-placeholder {
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-transform: none;
}
.pageWrapper textarea:-moz-placeholder,
footer textarea:-moz-placeholder,
.popup_wrapper textarea:-moz-placeholder {
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-transform: none;
}
.pageWrapper textarea::-moz-placeholder,
footer textarea::-moz-placeholder,
.popup_wrapper textarea::-moz-placeholder {
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-transform: none;
}
.pageWrapper textarea:-ms-input-placeholder,
footer textarea:-ms-input-placeholder,
.popup_wrapper textarea:-ms-input-placeholder {
  opacity: 0.4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-transform: none;
}
.pageWrapper textarea:hover::-webkit-input-placeholder, .pageWrapper textarea:focus::-webkit-input-placeholder,
footer textarea:hover::-webkit-input-placeholder,
footer textarea:focus::-webkit-input-placeholder,
.popup_wrapper textarea:hover::-webkit-input-placeholder,
.popup_wrapper textarea:focus::-webkit-input-placeholder {
  opacity: 0.4;
}
.pageWrapper textarea:hover:-moz-placeholder, .pageWrapper textarea:focus:-moz-placeholder,
footer textarea:hover:-moz-placeholder,
footer textarea:focus:-moz-placeholder,
.popup_wrapper textarea:hover:-moz-placeholder,
.popup_wrapper textarea:focus:-moz-placeholder {
  opacity: 0.4;
}
.pageWrapper textarea:hover::-moz-placeholder, .pageWrapper textarea:focus::-moz-placeholder,
footer textarea:hover::-moz-placeholder,
footer textarea:focus::-moz-placeholder,
.popup_wrapper textarea:hover::-moz-placeholder,
.popup_wrapper textarea:focus::-moz-placeholder {
  opacity: 0.4;
}
.pageWrapper textarea:hover:-ms-input-placeholder, .pageWrapper textarea:focus:-ms-input-placeholder,
footer textarea:hover:-ms-input-placeholder,
footer textarea:focus:-ms-input-placeholder,
.popup_wrapper textarea:hover:-ms-input-placeholder,
.popup_wrapper textarea:focus:-ms-input-placeholder {
  opacity: 0.4;
}
.pageWrapper textarea:focus,
footer textarea:focus,
.popup_wrapper textarea:focus {
  outline: 2px solid #64002E;
}
.pageWrapper select,
footer select,
.popup_wrapper select {
  width: 100%;
  /*max-width: 280px;*/
  color: #1A3053;
  cursor: pointer;
  border: 1px solid #64002E;
  padding: 0.2em 1em;
  font-size: 1rem;
  line-height: 1;
  font-family: "Gotham A", "Gotham B", Arial, sans-serif;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url(/CFImages/Icons/triangle-down-purple.svg);
  background-repeat: no-repeat;
  background-position: right 1em center;
  background-size: 0.6em auto;
  padding-right: 1.6em;
  height: 62.39px;
  text-transform: uppercase;
}
.pageWrapper select:focus,
footer select:focus,
.popup_wrapper select:focus {
  outline: none;
  outline: 2px solid #64002E;
}
.pageWrapper select > option,
footer select > option,
.popup_wrapper select > option {
  text-transform: none;
}
.pageWrapper select > option:checked, .pageWrapper select > option:hover,
footer select > option:checked,
footer select > option:hover,
.popup_wrapper select > option:checked,
.popup_wrapper select > option:hover {
  background-color: #E4EAED;
}
/*#endregion FORMS */
/*#region IMAGES */
div.bgImgWrapper {
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
div.bgImgWrapper a {
  display: block;
  width: 100%;
  height: 100%;
}
div.bgImgWrapper a span.bgImg {
  background-position: center;
  background-size: cover;
  display: block;
}
div.bgImgWrapper div.bgImg {
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

div.imgWrapper {
  width: 100%;
}
div.imgWrapper a img {
  max-width: 100%;
}
div.imgWrapper img {
  max-width: 100%;
}

div.bgImgWrapper.hoverZoom a span.bgImg, div.bgImgWrapper.hoverZoom div.bgImg {
  max-width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
div.bgImgWrapper.hoverZoom a span.bgImg:hover, div.bgImgWrapper.hoverZoom div.bgImg:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* Image Zoom in */
.parent:hover .child, .parent:focus .child {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/*#endregion IMAGES */
/*#region MISCELLANEOUS CLASSES SECTION*/
.equalTopMargins > :first-child {
  margin-top: 1em;
}

/* Combined Search Suggestions Dropdown */
.quickFindWrapper {
  border-bottom: 1px solid #B1B1B1;
  background-color: #1A3053;
  margin-bottom: 10px;
}
.quickFindWrapper .GOButton {
  display: none;
}
.quickFindWrapper .quickFindBox {
  margin: 0 auto;
  display: block;
  max-width: 400px;
  padding: 10px 10px;
  width: 100%;
}
.quickFindWrapper .quickFindBox .rcbInput {
  font-size: 18px;
  padding: 5px 40px;
  color: #64002E;
  background: url(/CFImages/Icons/icon_search.png) no-repeat 10px;
  background-size: 24px 25px;
}
.quickFindWrapper .quickFindBox .rcbInputCell {
  height: 48px !important;
  border: none;
  border-radius: 5px;
}
.quickFindWrapper .quickFindBox .rcbEmptyMessage {
  color: #64002E;
}

.quickFindWrapper .quickFindBox ul, .quickFindWrapper .quickFindBox ul.rcbList {
  list-style-type: none;
}

.quickFindWrapper .quickFindBox ul li, .quickFindWrapper .quickFindBox ul li.rcbItem, .quickFindWrapper .quickFindBox ul.rcbList li, .quickFindWrapper .quickFindBox ul.rcbList li.rcbItem {
  list-style-type: none;
}

/* ### Miscellaneous Global Classes ### */
.forcedHide {
  display: none !important;
}

.hideOnDesktop {
  display: none !important;
}

.hideOnMobile {
  display: inline-block;
}

img.baseImage, .baseImage img {
  display: block;
  width: 100%;
}

.pullQuote {
  padding: 30px 50px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  font-size: 130%;
  font-weight: 700;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.subtle {
  color: #b10051;
  font-size: 80%;
}

.clickable:hover {
  cursor: pointer;
}

.scrollToTop {
  width: 2rem;
  height: 2rem;
  display: none;
  top: auto;
  left: auto;
  right: 1rem;
  bottom: 7rem;
  position: fixed;
  font-size: 1.2rem;
  background-color: #1A3053;
  cursor: pointer;
  z-index: 1000;
  border: 3px solid white;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  opacity: 0.4;
}
.scrollToTop:focus, .scrollToTop:hover {
  text-decoration: none;
  background-color: #64002E;
  opacity: 1;
}
.scrollToTop i {
  color: white;
  line-height: 2rem;
  width: 100%;
  text-align: center;
}

#skiptocontent a {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
#skiptocontent a:focus, #skiptocontent a:active {
  color: #fff;
  background-color: #000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid #00D3FF;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

/* Special Links */
a[href^=tel], a[href^=Tel] {
  font-weight: 400;
  font-family: "Gotham A", "Gotham B", Arial, sans-serif;
  color: #08263D;
  /*&:before {
  content: "\260e";
  margin-right: 0.5em;
  }*/
}
a[href^=tel]:hover, a[href^=Tel]:hover {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
  color: #64002E;
}

.noLink {
  color: #08263D !important;
}
.noLink:hover {
  text-decoration: none !important;
}

.hidden {
  display: none !important;
}

.stickyLink {
  color: white;
  top: 30%;
  right: 0;
  z-index: 10;
  display: inline-block;
  position: fixed;
  font-size: 1.4rem;
  -webkit-transform: translateY(-100%) rotate(-90deg);
          transform: translateY(-100%) rotate(-90deg);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  background-color: #00557B;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
  overflow: hidden;
  display: none;
}
.stickyLink a {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.8rem 1.8rem 0.4rem;
}
.stickyLink a:hover, .stickyLink a:focus, .stickyLink a:active {
  text-decoration: none;
  background-color: #006795;
}
.stickyLink a:after {
  width: 0.75em;
  height: 0.75em;
  display: inline-block;
  content: " ";
  position: relative;
  background-size: 100% auto;
  background-image: url(/CFImages/Icons/arrow-up-teal.svg);
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 0.4em;
}

.stickyPhone {
  top: auto;
  left: auto;
  right: 1rem;
  bottom: 5%;
  margin: 0 1rem;
  position: fixed;
  color: white;
  font-size: 2rem;
  z-index: 10;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media (min-width: 768px) {
  .stickyPhone {
    display: none;
  }
}
.stickyPhone.left {
  left: 0;
  right: auto;
}
.stickyPhone.right {
  right: 0;
  left: auto;
}
.stickyPhone .stickyPhoneInner {
  color: inherit;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  overflow: hidden;
  background-color: #00557B;
}
.stickyPhone .stickyPhoneInner a {
  color: inherit;
  margin: 0;
  padding: 0;
  min-width: initial;
  display: block;
  line-height: 2em;
  text-align: center;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.counterWrapper {
  margin: 7rem 0 4rem;
}
.counterWrapper .counterGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.counterWrapper .counterGrid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 1em;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 .counterWrapper .counterGrid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
@media (max-width: 980px) {
  .counterWrapper .counterGrid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -1em;
    font-size: 1.4em;
  }
  .counterWrapper .counterGrid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 1em;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 2em);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 2em);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 2em);
            flex-basis: calc((1 / 2) * 100% - 2em);
  }
  html.k-ie.k-ie11 .counterWrapper .counterGrid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media (max-width: 767px) {
  .counterWrapper .counterGrid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -1em;
  }
  .counterWrapper .counterGrid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 1em;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 2em);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 2em);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 2em);
            flex-basis: calc((1 / 2) * 100% - 2em);
  }
  html.k-ie.k-ie11 .counterWrapper .counterGrid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
.counterWrapper .counterGrid .counterWrapper {
  border: solid 3px #00557B;
  border-radius: 50%;
  position: relative;
  color: white;
  text-align: center;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  width: 11em;
}
.counterWrapper .counterGrid .counterWrapper:before { /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.counterWrapper .counterGrid .counterWrapper:after { /* to clear float */
  content: "";
  display: table;
  clear: both;
}
.counterWrapper .counterGrid .counterWrapper h4 {
  width: 100%;
  color: inherit;
  margin: 0;
  padding: 0;
  font-size: 1.6em;
  line-height: 1em;
  line-height: 0.9em;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.counterWrapper .counterGrid .counterWrapper .value {
  display: block;
  font-weight: 100;
}
.counterWrapper .counterGrid .counterWrapper .counter {
  /*@include fluid-type($L_Min, $XL_Min, 16px, 20px);*/
}

/*#region OPEN STREET MAPS */
.openStreetMap {
  min-height: 600px;
  background-color: #cccccc;
  margin: 1em 0;
}

.mapbox-improve-map {
  display: none;
}

.marker {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.marker:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f3c5";
  color: #64002E;
  font-family: "Font Awesome 5 Pro";
  font-size: 50px;
  font-weight: 700;
}

.mapboxgl-popup {
  max-width: 300px;
}
.mapboxgl-popup .mapboxgl-popup-content {
  padding: 1.2rem;
  text-align: center;
  font-family: "Gotham A", "Gotham B", Arial, sans-serif;
  font-size: 1.3em;
}

/*#endregion OPEN STREET MAPS */
.googleMapsOverlay {
  background: transparent;
  position: relative;
  width: 100%;
  height: 500px;
  top: 500px; /* your iframe height */
  margin-top: -500px; /* your iframe height */
}

/*#endregion MISCELLANEOUS CLASSES SECTION*/
/*#region contentTypeLabels SECTION*/
.contentTypeLabels span.labelValuesSeperator:before {
  content: ": ";
}
.contentTypeLabels span.seperator:before {
  content: " | ";
}

/*#endregion contentTypeLabels SECTION*/
/*#region dates SECTION*/
/*#endregion dates SECTION*/
/* #region HOME SECTION*/
.carousel .slide, .carousel .slick-slide,
.slick-slider .slide,
.slick-slider .slick-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*display: none;*/
  outline: none !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.carousel .slick-track,
.slick-slider .slick-track {
  outline: none !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.carousel .slick-track .slide, .carousel .slick-track .slick-slide,
.slick-slider .slick-track .slide,
.slick-slider .slick-track .slick-slide {
  height: auto;
}
.carousel .slide:not(:first-child), .carousel .slick-slide:not(:first-child),
.slick-slider .slide:not(:first-child),
.slick-slider .slick-slide:not(:first-child) {
  display: none;
}
.carousel .slick-arrow,
.slick-slider .slick-arrow {
  background: none;
}
.carousel .slick-arrow:before,
.slick-slider .slick-arrow:before {
  color: #707070;
}
.carousel.slick-initialized .slide, .carousel.slick-initialized .slick-slide,
.slick-slider.slick-initialized .slide,
.slick-slider.slick-initialized .slick-slide {
  display: block;
}
.carousel.slick-initialized .slide.slick-current .innerContent, .carousel.slick-initialized .slide.slick-active .innerContent, .carousel.slick-initialized .slick-slide.slick-current .innerContent, .carousel.slick-initialized .slick-slide.slick-active .innerContent,
.slick-slider.slick-initialized .slide.slick-current .innerContent,
.slick-slider.slick-initialized .slide.slick-active .innerContent,
.slick-slider.slick-initialized .slick-slide.slick-current .innerContent,
.slick-slider.slick-initialized .slick-slide.slick-active .innerContent {
  /*opacity: 1;*/
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}

.homePeopleSearch {
  background-color: #E6F6FA;
  padding: 2em;
}
.homePeopleSearch .findASearch {
  margin: 0 auto 2em;
  text-align: center;
}
.homePeopleSearch .findASearch .k-widget.findASearchInput {
  width: 45em !important;
  border: solid 2px #1A3053;
  font-size: 1.1rem;
  border-radius: 0;
}
.homePeopleSearch .findASearch .k-widget.findASearchInput .k-select {
  background: transparent url(/CFImages/Icons/search-pipe.svg) no-repeat 0 50%;
}
.homePeopleSearch .findASearch .k-widget.findASearchInput .k-select .k-icon:before {
  content: "";
}
.homePeopleSearch .sf-Long-text {
  text-align: center;
}
.homePeopleSearch .sf-Long-text a {
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
}
.homePeopleSearch .sf-Long-text a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #1A3053;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.homePeopleSearch .sf-Long-text a:hover:before {
  -webkit-transform: scaleX(0.5);
          transform: scaleX(0.5);
}

.homeResources .itemImage {
  min-height: 500px;
}
.homeResources .resources .innerContent {
  position: relative;
  border: none;
  padding: 5rem;
}
.homeResources .odd article {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

/*#region offices SECTION*/
.offices {
  padding-top: 100px;
}
.offices > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 0;
}
.offices > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0;
  vertical-align: top;
  /*width: calc((1 / 2) * 100%);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100%);
      -ms-flex-preferred-size: calc((1 / 2) * 100%);
          flex-basis: calc((1 / 2) * 100%);
}
html.k-ie.k-ie11 .offices > .grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.offices > .grid > .col .itemImage {
  min-height: 700px;
}
.offices > .grid > .col .itemContent {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
}
.offices > .grid > .col:nth-child(even) article {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.offices > .grid article.grid {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.offices > .grid .col:last-child {
  margin-bottom: 2em;
}

.office .pageBanner {
  min-height: 700px;
}
.office .pageBanner .bannerImage .bgImg {
  background-position: top;
}
.office .pageBanner .innerContainer {
  max-height: 550px;
  color: #fff;
  overflow: hidden;
}
.office .pageBanner .innerContainer .extraBannerContent {
  position: relative;
  z-index: 2;
  width: 50%;
}
.office .placeOverBanner {
  position: absolute;
  margin-top: -200px;
  left: 5em;
  color: #fff;
}
.office .placeOverBanner a {
  color: #fff;
}
.office .placeOverBanner ul.officeContact {
  padding: 0.5em 0;
}

.homeSlideshow {
  position: relative;
}
.homeSlideshow .slide {
  background-color: #64002E;
  color: white;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.homeSlideshow .slide .bgImgWrapper .bgImg:before {
  position: relative;
  display: block;
  height: 100%;
  content: " ";
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(0, 0, 0)), color-stop(60%, rgba(63, 108, 118, 0.5)));
  background-image: linear-gradient(to right, rgb(0, 0, 0) 0%, rgba(63, 108, 118, 0.5) 60%);
  opacity: 0.6;
  z-index: 2;
}
.homeSlideshow .slide .contentWrapper {
  position: relative;
  height: 42rem; /*height: approx 850px;*/
  z-index: 4;
  width: -webkit-calc(2160px - 2rem);
  width: calc(2160px - 2rem);
  padding-left: 5rem;
  padding-right: 5rem;
  margin: 0 auto;
}
.homeSlideshow .slide .contentWrapper .innerContent {
  width: 40%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  opacity: 0;
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
}
.homeSlideshow .slide .contentWrapper .innerContent .topContent {
  text-align: left;
  font-weight: 100;
  margin-top: 4em;
}
.homeSlideshow .slide .contentWrapper .innerContent .topContent p {
  font-weight: 100;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent {
  text-align: left;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent h1.branded {
  line-height: 1.1em;
  font-size: 300%;
  text-align: left;
  color: #fff;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent h1.branded:after {
  line-height: 1.5em;
  bottom: 0;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent .summary {
  font-size: 1em;
  font-weight: 100;
}
.homeSlideshow .slide .contentWrapper .innerContent .bottomContent .button {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 30px;
}
.homeSlideshow .slide.slick-active .innerContent .content {
  opacity: 1;
}
.homeSlideshow .slide.right .contentWrapper .innerContent {
  float: right;
  text-align: right;
}
.homeSlideshow .slide.left .contentWrapper .innerContent {
  float: left;
  text-align: left;
}
.homeSlideshow .slide.centred .coverImg:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: block;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
}
.homeSlideshow .slick-arrow.slick-prev, .homeSlideshow .slick-arrow.slick-next {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: absolute;
  background-color: #fff;
  -webkit-transform: none;
          transform: none;
  top: 80%;
}
.homeSlideshow .slick-arrow.slick-prev:before, .homeSlideshow .slick-arrow.slick-next:before {
  color: #1A3053;
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
}
.homeSlideshow .slick-arrow.slick-prev {
  left: -webkit-calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
}
.homeSlideshow .slick-arrow.slick-next {
  left: -webkit-calc(50% + 1rem);
  left: calc(50% + 1rem);
}
.homeSlideshow ul.slick-dots {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  position: absolute;
  bottom: 50%;
  right: 3em;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
          align-content: flex-end;
}
.homeSlideshow ul.slick-dots > li {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 .homeSlideshow ul.slick-dots > li > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.homeSlideshow ul.slick-dots li {
  background: none;
  vertical-align: top;
}
.homeSlideshow ul.slick-dots li button:before {
  font-size: 30px;
  color: #fff;
  opacity: 0.7;
}
.homeSlideshow ul.slick-dots li button:hover:before {
  color: #707070;
}
.homeSlideshow ul.slick-dots li.slick-active button:before {
  color: #1A3053;
  opacity: 1;
}

/* #endregion HOME SECTION*/
/* #region GRIDS SECTION*/
.itemGrid .item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -10px;
  position: relative;
  min-height: 400px;
  /*border: solid 1px #f1f1f1;*/
  /*background-color: $secondaryColour;*/
}
.itemGrid .item > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 10px;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 20px);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 20px);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 20px);
          flex-basis: calc((1 / 3) * 100% - 20px);
}
html.k-ie.k-ie11 .itemGrid .item > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.itemGrid .item a {
  color: #fff;
}
.itemGrid .item .innerContent {
  color: #fff;
  position: absolute;
  z-index: 5;
  /*top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
}
.itemGrid .item .innerContent h3 {
  /*font-size: 2rem;*/
  margin: 0;
  padding: 0;
  color: #fff;
  text-transform: uppercase;
}
.itemGrid .item .bgImgWrapper {
  z-index: 0;
}

/* #endregion GRIDS SECTION*/
/* #region TEAM SECTION*/
/* #region TEAM LISTING and RELATED TEAM */
.teamgroup h2.branded {
  text-align: center;
}

.teammembers > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.teammembers > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
          flex-basis: calc((1 / 3) * 100% - 1rem);
}
html.k-ie.k-ie11 .teammembers > .grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.teammembers .teammember {
  color: #08263D;
  position: relative;
  margin-bottom: 2em;
  font-size: 20px;
  line-height: 1.5;
}
.teammembers .teammember .innerContent {
  padding-top: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: center;
}
.teammembers .teammember .innerContent div.position {
  color: #64002E;
  font-size: 24px;
  font-family: "canada-type-gibson", sans-serif;
}
.teammembers .teammember .innerContent h3 {
  font-size: 30px;
}
.teammembers .teammember .innerContent h3 a {
  margin-bottom: 0.5em;
  color: #1A3053;
}
.teammembers .teammember .innerContent h3, .teammembers .teammember .innerContent div {
  width: 100%;
}
.teammembers .teammember .innerContent .bgImgWrapper {
  position: relative;
  border-radius: 50%;
  border: 1px solid #E4EAED;
  -webkit-box-shadow: #E4EAED 5px;
          box-shadow: #E4EAED 5px;
  overflow: hidden;
  max-width: 238px;
}
.teammembers .teammember .innerContent .bgImgWrapper .bgImg {
  background-size: cover;
}
.teammembers .teammember .innerContent .bgImgWrapper .bgImg:before { /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.teammembers .teammember .innerContent .bgImgWrapper .bgImg:after { /* to clear float */
  content: "";
  display: table;
  clear: both;
}
.teammembers .teammember .innerContent .teamBioButton {
  bottom: 0;
  position: absolute;
}
.teammembers .teammember .innerContent .teamBioButton a {
  margin: 0;
  margin-top: 25px;
  padding: 10px 25px 10px 25px;
  border-radius: 10px;
  font-size: 18px;
  letter-spacing: 0.2em;
}
.teammembers .teammember .bgImgWrapper {
  width: 100%;
}
.teammembers .teammember .bgImgWrapper:before { /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.teammembers .teammember .bgImgWrapper:after { /* to clear float */
  content: "";
  display: table;
  clear: both;
}
.teammembers .teammember .bgImgWrapper a span.bgImg {
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
}
.teammembers .teammember .content {
  text-align: center;
}
.teammembers .teammember .content ul.contactInfo {
  margin-top: 1em;
}
.teammembers .teammember .content ul.contactInfo li {
  margin: 0 0 0.25em 0;
}
.teammembers .teammember .content ul.contactInfo li span.prefixLabel {
  padding-right: 0.25em;
}
.teammembers .teammember .content .offices ul li {
  display: inline-block;
  border-right: 1px solid #1A3053;
  padding: 0 0.25em;
}
.teammembers .teammember .content .offices ul li a {
  color: #1A3053;
}
.teammembers .teammember .content .offices ul li:last-of-type {
  border: none;
}
.teammembers .teammember .content ul.socialLinks {
  margin-top: 0.75em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.teammembers .teammember .content ul.socialLinks li {
  padding: 0 0.25em;
}
.teammembers .teammember .content ul.socialLinks li a {
  color: #1A3053;
}
.teammembers .teammember:hover .bgImgWrapper a span.bgImg {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 1099px) {
  .teammembers > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .teammembers > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 3) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
            flex-basis: calc((1 / 3) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .teammembers > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media only screen and (max-width: 767px) {
  .teammembers > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .teammembers > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .teammembers > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media only screen and (max-width: 500px) {
  .teammembers > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .teammembers > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .teammembers > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}

.contactFormContainer {
  padding-top: 50px;
  padding-bottom: 50px;
}
.contactFormContainer .contactForm {
  padding: 25px;
  border: 1px solid #64002E;
}
.contactFormContainer .contactForm h4 strong, .contactFormContainer .contactForm h3 strong {
  color: #1A3053;
  font-weight: bold;
}
.contactFormContainer .contactForm button {
  margin: 0;
  margin-top: 25px;
  padding: 10px 25px 10px 25px;
  border-radius: 10px;
  font-size: 18px;
  letter-spacing: 0.2em;
}
.resource li {
  border-bottom: 2px solid #707070;
  padding-top: 20px;
  padding-bottom: 20px;
}
.resource li:last-child {
  border-bottom: none;
}

.teammembers.authorView > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.teammembers.authorView > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
          flex-basis: calc((1 / 2) * 100% - 1rem);
}
html.k-ie.k-ie11 .teammembers.authorView > .grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.teammembers.authorView > .grid .col {
  max-width: 600px;
}
@media only screen and (max-width: 1024px) {
  .teammembers.authorView > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .teammembers.authorView > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .teammembers.authorView > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
.teammembers.authorView .teammember {
  color: #ffffff;
  position: relative;
  margin-bottom: 1em;
}
.teammembers.authorView .teammember .bgImgWrapper {
  width: 100%;
}
.teammembers.authorView .teammember .bgImgWrapper:before { /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 62.5%;
}
.teammembers.authorView .teammember .bgImgWrapper:after { /* to clear float */
  content: "";
  display: table;
  clear: both;
}
.teammembers.authorView .teammember .bgImgWrapper a {
  position: absolute;
}
.teammembers.authorView .teammember .bgImgWrapper a span.bgImg {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  margin-top: 3%;
  margin-right: -30%;
  background-position: top right;
}
.teammembers.authorView .teammember .bgImgWrapper a:before {
  position: relative;
  display: block;
  height: 100%;
  content: " ";
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(51, 50, 50)), color-stop(50%, rgba(51, 50, 50, 0)));
  background-image: linear-gradient(to right, rgb(51, 50, 50), rgba(51, 50, 50, 0) 50%);
  z-index: 2;
}
.teammembers.authorView .teammember .content {
  width: 70%;
  opacity: 1;
  padding: 0.5em 1em 0 1em;
  color: #fff;
  text-align: left;
}
.teammembers.authorView .teammember .content a:link, .teammembers.authorView .teammember .content a:visited, .teammembers.authorView .teammember .content a:active {
  color: #fff;
}
.teammembers.authorView .teammember .content h3 {
  margin-bottom: 0.25em;
}
.teammembers.authorView .teammember .content span.position {
  font-weight: 500;
  font-size: 0.9em;
  display: block;
  margin-bottom: 1em;
}
.teammembers.authorView .teammember .content ul.contactInfo {
  margin-top: 1em;
}
.teammembers.authorView .teammember .content ul.contactInfo li {
  margin: 0 0 0.25em 0;
}
.teammembers.authorView .teammember .content ul.contactInfo li span.prefixLabel {
  padding-right: 0.25em;
}
.teammembers.authorView .teammember .content .offices ul li {
  border-right: 1px solid #fff;
}
.teammembers.authorView .teammember .content .offices ul li a {
  color: #fff;
}
.teammembers.authorView .teammember .content .offices ul li:first-of-type {
  padding-left: 0;
}
.teammembers.authorView .teammember .content .offices ul li:last-of-type {
  border: none;
}
.teammembers.authorView .teammember .hoverContent {
  opacity: 0;
  padding: 0.5em 1em 0 1em;
  background-color: #00557B;
  text-align: left;
  height: 100%;
  color: #fff;
}
.teammembers.authorView .teammember .hoverContent a {
  color: #fff;
}
.teammembers.authorView .teammember .hoverContent div.shortBio {
  padding-top: 1em;
  text-align: left;
  max-height: 50%;
  overflow: hidden;
}
.teammembers.authorView .teammember .hoverContent .button {
  position: absolute;
  right: 1em;
  bottom: 1em;
}
.teammembers.authorView .teammember:hover .content {
  opacity: 0;
  display: none;
}
.teammembers.authorView .teammember:hover .hoverContent {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  opacity: 0.9;
}

.cardView .teammember {
  height: 650px;
}

/* #region TEAM BIO PAGE */
.teammember .blueSection {
  height: unset;
}
.teammember ul.socialLinks {
  margin-top: 0.75em;
}
.teammember ul.socialLinks li {
  margin-right: 0.5em;
}
.teammember ul.socialLinks li a {
  color: #707070;
}
.teammember ul.socialLinks li a:hover {
  color: #64002E;
}
.teammember ul.socialLinks li a em {
  font-size: 35px;
}
.teammember .printModal {
  margin: 0 auto;
  max-width: 60%;
  padding: 2em 3em;
  display: none;
}
.teammember .printModal .top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.teammember .printModal .top > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
          flex-basis: calc((1 / 2) * 100% - 1rem);
}
html.k-ie.k-ie11 .teammember .printModal .top > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.teammember .printModal .bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.teammember .printModal .bottom > .options {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
          flex-basis: calc((1 / 2) * 100% - 1rem);
}
html.k-ie.k-ie11 .teammember .printModal .bottom > .options > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.teammember .printModal .bottom .options b {
  text-transform: uppercase;
}
.teammember .bannerGrid {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.teammember .bannerGrid .bioTopContent.flexCol {
  padding: 1.5em 0 1.5em 0;
  color: #08263D;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact {
  text-align: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact h1 {
  font-size: 60px;
  margin: 0;
  margin-bottom: 25px;
  color: #08263D;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact a:link, .teammember .bannerGrid .bioTopContent.flexCol .bioContact a:visited, .teammember .bannerGrid .bioTopContent.flexCol .bioContact a:active {
  color: #fff;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact span.position {
  color: #08263D;
  font-size: 45px;
  font-weight: 500;
  display: block;
  font-family: "canada-type-gibson" sans-serif;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact span.languages {
  margin-top: 0.5em;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact ul.contactInfo {
  margin-top: 234.6px;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact ul.contactInfo li {
  margin: 0 0 0.25em 0;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact ul.contactInfo li span.prefixLabel {
  padding-right: 0.25em;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact .offices {
  margin-top: 1.5em;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact .offices ul li {
  border-right: 1px solid #fff;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 0.5em;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact .offices ul li a {
  color: #fff;
  font-size: 1.5em;
  font-weight: 500;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact .offices ul li:first-of-type {
  padding-left: 0;
}
.teammember .bannerGrid .bioTopContent.flexCol .bioContact .offices ul li:last-of-type {
  border: none;
}
.teammember .bannerGrid .imgWrapper img {
  border-radius: 50%;
  position: absolute;
  right: 0%;
  -webkit-transform: scale(1.7);
          transform: scale(1.7);
  top: -webkit-calc(100% - 138px);
  top: calc(100% - 138px);
  margin-right: -webkit-calc(2rem + 138px * 0.6);
  margin-right: calc(2rem + 138px * 0.6);
  width: 200px;
  height: 200px;
}
.teammember .bannerGrid .bgImgWrapper.flexCol {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 100%;
}
.teammember .bannerGrid .bgImgWrapper.flexCol div.bgImg {
  border-radius: 50%;
  position: absolute;
  background-position: top 90%;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
}
.teammember .topSection {
  padding-top: 2em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
.teammember .topSection .contactInfoColumn {
  margin-top: 4rem;
}
.teammember .topSection .contactInfoColumn h3 {
  color: #64002E;
}
.teammember .topSection .contactInfoColumn .offices {
  padding-top: 0px;
}
.teammember .topSection .contactInfoColumn ul {
  line-height: 1.5;
}
.teammember .topSection .contactInfoColumn ul p {
  margin-bottom: 0px;
  line-height: 1.5;
}
.teammember .topSection .mainContent .cmsContent a {
  color: #64002E;
}
.teammember .topSection .mainContent .cmsContent a:hover {
  color: #1A3053;
}
.teammember .topSection .mainContent .cmsContent ul {
  list-style: none;
}
.teammember .topSection .mainContent .cmsContent ul li {
  list-style: none;
  padding-left: 1em;
}
.teammember .topSection .mainContent .cmsContent ul li:before {
  top: 1em;
  left: 0em;
  color: #64002E;
  content: "\f111";
  display: inline-block;
  position: absolute;
  font-size: 0.5em;
  line-height: 2em;
}
.teammember .topSection .mainContent .cmsContent h2.branded {
  text-transform: none;
}
.teammember .topSection .secondaryContent .credentials .credentialsInner {
  margin: 1em 0;
}
.teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li:first-child {
  border-top: none;
}
.teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li:last-child {
  border-bottom: 1px solid #707070;
}
.teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li, .teammember .topSection .secondaryContent .credentials .credentialsInner .educationitems ul li {
  position: relative;
  border-top: 1px solid #707070;
  padding: 0.5em 0.25em;
  padding-left: 1.5em;
}
.teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li span.spacer, .teammember .topSection .secondaryContent .credentials .credentialsInner .educationitems ul li span.spacer {
  display: block;
  height: 0.5em;
}
.teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li:before, .teammember .topSection .secondaryContent .credentials .credentialsInner .educationitems ul li:before {
  position: absolute;
  top: 3em;
  left: 1.5em;
  color: #1A3053;
  content: "\f111";
  font-size: 0.3em;
}
.teammember .topSection .secondaryContent .credentials .credentialsInner .calltobars ul li:first-child, .teammember .topSection .secondaryContent .credentials .credentialsInner .educationitems ul li:first-child {
  border-top: none;
}
.teammember .resources.cases > ul.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.teammember .resources.cases > ul.grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
html.k-ie.k-ie11 .teammember .resources.cases > ul.grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
@media only screen and (max-width: 1099px) {
  .teammember .resources.cases > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .teammember .resources.cases > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 3) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
            flex-basis: calc((1 / 3) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .teammember .resources.cases > ul.grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .teammember .resources.cases > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .teammember .resources.cases > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .teammember .resources.cases > ul.grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media only screen and (max-width: 767px) {
  .teammember .resources.cases > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .teammember .resources.cases > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .teammember .resources.cases > ul.grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
.teammember .resources.cases .innerContent {
  min-height: 300px;
}
.teammember .k-tabstrip-wrapper {
  margin-top: 2em;
  padding: 2em 5em;
  background-color: #E6F6FA;
}
.teammember .k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items {
  padding-bottom: 1.5em;
}
.teammember .k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items .k-item .k-link {
  padding: 0 1em;
  border-right: solid 2px #00557B;
  border-radius: 0;
}
.teammember .k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items .k-item.k-first .k-link {
  padding-left: 0;
}
.teammember .k-tabstrip-wrapper .k-tabstrip .k-tabstrip-items .k-item.k-last .k-link {
  border-right: none;
}
.teammember .k-tabstrip-wrapper .k-tabstrip .tab-pane {
  padding: 0;
  border-top: solid 1px #00557B;
}
.teammember .k-tabstrip-wrapper .k-tabstrip .tab-pane article {
  border-bottom: solid 1px #00557B;
  padding: 1.5em 0;
}
.teammember .k-tabstrip-wrapper .k-tabstrip .tab-pane article h3 {
  font-size: 1.25em;
  padding: 0;
  margin: 0;
}
.teammember .k-tabstrip-wrapper .k-tabstrip .tab-pane article h3 a {
  color: #000;
}

/* #endregion TEAM BIO PAGE */
/*#region SECTORS / EXPERTISE SECTION*/
.sectors > .grid, .expertises > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.sectors > .grid > .col, .expertises > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
          flex-basis: calc((1 / 2) * 100% - 1rem);
}
html.k-ie.k-ie11 .sectors > .grid > .col > *, html.k-ie.k-ie11 .expertises > .grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.sectors > .grid .col, .expertises > .grid .col {
  max-width: 1080px;
  margin-bottom: 2em;
  position: relative;
}
.sectors > .grid .col:hover .bgImgWrapper a span.bgImg, .expertises > .grid .col:hover .bgImgWrapper a span.bgImg {
  -webkit-transform: scale(1.35);
          transform: scale(1.35);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.sectors > .grid .col:hover .bgImgWrapper a:before, .expertises > .grid .col:hover .bgImgWrapper a:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#333232), to(rgba(51, 50, 50, 0.75)));
  background-image: linear-gradient(to right, #333232, rgba(51, 50, 50, 0.75) 100%);
}
.sectors > .grid .col .bgImgWrapper, .expertises > .grid .col .bgImgWrapper {
  position: relative;
}
.sectors > .grid .col .bgImgWrapper:before, .expertises > .grid .col .bgImgWrapper:before { /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 37.037037037%;
}
.sectors > .grid .col .bgImgWrapper:after, .expertises > .grid .col .bgImgWrapper:after { /* to clear float */
  content: "";
  display: table;
  clear: both;
}
.sectors > .grid .col .bgImgWrapper a, .expertises > .grid .col .bgImgWrapper a {
  display: block;
  position: relative;
}
.sectors > .grid .col .bgImgWrapper a:before, .expertises > .grid .col .bgImgWrapper a:before {
  position: relative;
  display: block;
  height: 100%;
  content: " ";
  background-image: -webkit-gradient(linear, left top, right top, from(#00778b), to(rgba(0, 119, 139, 0)));
  background-image: linear-gradient(to right, #00778b, rgba(0, 119, 139, 0) 100%);
  z-index: 2;
}
.sectors > .grid .col h3, .expertises > .grid .col h3 {
  position: absolute;
  left: 1em;
  font-size: 1.2em;
  top: 1em;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
  text-transform: uppercase;
}
.sectors > .grid .col h3 a:link, .sectors > .grid .col h3 a:visited, .sectors > .grid .col h3 a:active, .expertises > .grid .col h3 a:link, .expertises > .grid .col h3 a:visited, .expertises > .grid .col h3 a:active {
  color: #fff;
}
.sectors > .grid .col h3 a:hover, .sectors > .grid .col h3 a:focus, .expertises > .grid .col h3 a:hover, .expertises > .grid .col h3 a:focus {
  color: #1A3053;
}
@media only screen and (max-width: 1024px) {
  .sectors > .grid, .expertises > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .sectors > .grid > .col, .expertises > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .sectors > .grid > .col > *, html.k-ie.k-ie11 .expertises > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}

/*#endregion SECTORS SECTION*/
/*#region EXPERTISE / SECTORS SECTION*/
.expertises.accordionUL ul.level1 li:last-child, .sectors.accordionUL ul.level1 li:last-child {
  border-bottom: 1px solid #B1B1B1;
}
.expertises.accordionUL ul.level1 li, .expertises.accordionUL ul.level1 li.hasChildren, .sectors.accordionUL ul.level1 li, .sectors.accordionUL ul.level1 li.hasChildren {
  text-transform: uppercase;
  position: relative;
  padding: 0.75em 1em;
  border-top: 1px solid #B1B1B1;
  font-weight: 700;
  font-size: 0.9rem;
}
.expertises.accordionUL ul.level1 li ul, .expertises.accordionUL ul.level1 li.hasChildren ul, .sectors.accordionUL ul.level1 li ul, .sectors.accordionUL ul.level1 li.hasChildren ul {
  padding-left: 0;
}
.expertises.accordionUL ul.level1 li ul li, .expertises.accordionUL ul.level1 li.hasChildren ul li, .sectors.accordionUL ul.level1 li ul li, .sectors.accordionUL ul.level1 li.hasChildren ul li {
  border: none;
  font-weight: 400;
  padding: 0.25em 0;
}
.expertises.accordionUL ul.level1 li ul li:before, .expertises.accordionUL ul.level1 li.hasChildren ul li:before, .sectors.accordionUL ul.level1 li ul li:before, .sectors.accordionUL ul.level1 li.hasChildren ul li:before {
  top: 2em;
}
.expertises.accordionUL ul.level1 li.hasChildren.active:before, .sectors.accordionUL ul.level1 li.hasChildren.active:before {
  opacity: 0.1;
  background-color: #00778B;
  content: " ";
}
.expertises.accordionUL ul.level1 li.hasChildren.active a, .sectors.accordionUL ul.level1 li.hasChildren.active a {
  position: relative;
  z-index: 2;
}
.expertises.accordionUL ul.level1 > li.hasChildren > ul:before, .sectors.accordionUL ul.level1 > li.hasChildren > ul:before {
  border-bottom: 1px solid #B1B1B1;
  content: " ";
  display: block;
  left: 0;
  height: 1em;
  margin: 0 -1em 0.5em -1em;
}

.expertise.detailView div.padded.centered, .sector.detailView div.padded.centered {
  padding: 1em 6em;
  text-align: center;
}
.expertise.detailView .pageBanner, .sector.detailView .pageBanner {
  padding-top: 5rem;
  min-height: 25rem;
  margin-bottom: 2em;
}
.expertise.detailView .pageBanner .innerContainer, .sector.detailView .pageBanner .innerContainer {
  z-index: 1;
}
.expertise.detailView .cmsContent h2.branded, .sector.detailView .cmsContent h2.branded {
  text-transform: none;
}
.expertise.detailView .cmsContent > p:nth-of-type(1), .sector.detailView .cmsContent > p:nth-of-type(1) {
  font-size: 150% !important;
  line-height: 1em;
}

.firstParagraphHighlight {
  font-size: 150% !important;
  line-height: 1em;
}

/*#endregion PRACTICE AREA SECTION*/
/*#region RANKINGS SECTION*/
.awardrankings, .rankings {
  text-align: center;
}
.awardrankings > .grid, .rankings > .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.awardrankings > .grid > .col, .rankings > .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 5) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 5) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 5) * 100% - 1rem);
          flex-basis: calc((1 / 5) * 100% - 1rem);
}
html.k-ie.k-ie11 .awardrankings > .grid > .col > *, html.k-ie.k-ie11 .rankings > .grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
@media (max-width: 1099px) {
  .awardrankings > .grid, .rankings > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .awardrankings > .grid > .col, .rankings > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 4) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
            flex-basis: calc((1 / 4) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .awardrankings > .grid > .col > *, html.k-ie.k-ie11 .rankings > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media (max-width: 1050px) {
  .awardrankings > .grid, .rankings > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .awardrankings > .grid > .col, .rankings > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 3) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
            flex-basis: calc((1 / 3) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .awardrankings > .grid > .col > *, html.k-ie.k-ie11 .rankings > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media (max-width: 800px) {
  .awardrankings > .grid, .rankings > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .awardrankings > .grid > .col, .rankings > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .awardrankings > .grid > .col > *, html.k-ie.k-ie11 .rankings > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media (max-width: 550px) {
  .awardrankings > .grid, .rankings > .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .awardrankings > .grid > .col, .rankings > .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .awardrankings > .grid > .col > *, html.k-ie.k-ie11 .rankings > .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
.awardrankings > .grid > .col, .rankings > .grid > .col {
  margin-bottom: 3em;
}
.awardrankings span, .rankings span {
  display: block;
}
.awardrankings span.yearsAwarded, .rankings span.yearsAwarded {
  display: block;
  padding: 0.25em;
  color: #000;
  font-weight: 700;
}
.awardrankings span.yearsAwarded:before, .rankings span.yearsAwarded:before {
  display: block;
  margin: 0 auto;
  content: " ";
  width: 40%;
  background-color: #1A3053;
  height: 2px;
}
.awardrankings .awardTop, .rankings .awardTop {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 12em;
  padding: 1em;
  margin-bottom: 0.25em;
}
.awardrankings .awardTop:before, .rankings .awardTop:before {
  content: " ";
  background-color: #00778B;
  opacity: 0.1;
}
.awardrankings .awardTop span.awardPublisher, .rankings .awardTop span.awardPublisher {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
}
.awardrankings .awardTop h4, .rankings .awardTop h4 {
  color: #1A3053;
  font-size: 1.5em;
}
.awardrankings .awardBottom span.awardPublication, .rankings .awardBottom span.awardPublication {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 4em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #00557B;
  font-style: italic;
  padding: 0.25em 1em;
}

/*#endregion Rankings SECTION*/
/*#region Featured Content SECTION*/
.featuredcontentgroups {
  font-size: 0.8em;
}
.featuredcontentgroups div.item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.featuredcontentgroups div.item .coverImg {
  min-height: 550px;
}
.featuredcontentgroups div.item .coverImg .bgImgWrapper .bgImg {
  width: 100%;
}
.featuredcontentgroups div.item .contentWrapper {
  width: 50%;
  position: relative;
  padding: 3em;
}
.featuredcontentgroups div.item .contentWrapper > * {
  z-index: 3;
  position: relative;
}
.featuredcontentgroups div.item .contentWrapper:before {
  content: " ";
  position: absolute;
  background-color: #fff;
  opacity: 0.4;
  z-index: 0;
}
.featuredcontentgroups div.item:nth-child(even) .contentWrapper {
  margin-left: auto;
}

/*#endregion Featured Content SECTION*/
/*#region Expandable Lists SECTION*/
.sf-list--expandable {
  font-size: 1em;
}
.sf-list--expandable .sf-list-item a {
  width: 100%;
  display: block;
  margin: 1em 0;
  padding: 1.5em 1.75em;
  position: relative;
  border-bottom: 1px solid #707070;
  font-weight: 700;
}
.sf-list--expandable .sf-list-item a:after {
  content: "+";
  font-size: 1.5em;
  color: #1A3053;
  position: absolute;
  right: 1em;
  top: 0.75em;
}
.sf-list--expandable .sf-list-item a.expanded:after {
  content: "-";
}
.sf-list--expandable .sf-list-item .sf-list-content {
  padding: 1.5em;
  margin-bottom: 1em;
}

/*#endregion Expandable Lists SECTION*/
/*#region Newsletter Archives SECTION*/
.newsletters .toggleAction {
  border-top: 2px solid #64002E;
  position: relative;
}
.newsletters .toggleAction span.toggle {
  position: absolute;
  right: 0;
  color: #1A3053;
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  cursor: pointer;
}
.newsletters .toggleAction span.toggle:after {
  content: "+";
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.newsletters .toggleContent {
  display: none;
}
.newsletters.active span.toggle:after {
  content: "-";
}
.newsletters .grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.newsletters .grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 5) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 5) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 5) * 100% - 1rem);
          flex-basis: calc((1 / 5) * 100% - 1rem);
}
html.k-ie.k-ie11 .newsletters .grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
@media (max-width: 1099px) {
  .newsletters .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .newsletters .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 4) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
            flex-basis: calc((1 / 4) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .newsletters .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media (max-width: 1050px) {
  .newsletters .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .newsletters .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 3) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
            flex-basis: calc((1 / 3) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .newsletters .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media (max-width: 800px) {
  .newsletters .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .newsletters .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .newsletters .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media (max-width: 550px) {
  .newsletters .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .newsletters .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .newsletters .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
.newsletters .grid > .col {
  margin-bottom: 1em;
}
.newsletters li a:hover {
  text-decoration: none;
}
.newsletters li a:hover .newsletterIssue {
  background-color: #B1B1B1;
}
.newsletters li a .newsletterIssue {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 12em;
  padding: 2em 1em 1em 1em;
  margin-bottom: 0.25em;
  text-align: center;
}
.newsletters li a .newsletterIssue:before {
  content: " ";
  background-color: #00778B;
  opacity: 0.1;
}
.newsletters li a .newsletterIssue span {
  display: block;
}
.newsletters li a .newsletterIssue span.newsletterType {
  text-align: right;
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.8em;
  position: absolute;
  top: 1em;
  right: 1em;
  width: -webkit-calc(100% - 2em);
  width: calc(100% - 2em);
  padding-bottom: 4px;
  border-bottom: 2px solid #1A3053;
}
.newsletters li a .newsletterIssue h4 {
  color: #1A3053;
  font-size: 1.1em;
}

/*#endregion Newsletter Archives SECTION*/
/*#region TESTIMONIALS SECTION*/
.testimonialsWrapper {
  min-height: initial;
}

.testimonials .testimonial {
  padding: 6em 0;
  text-align: center;
}
.testimonials .testimonial:before {
  display: none;
}
.testimonials .testimonial .content {
  font-size: 1.25em;
  line-height: 1em;
  margin-bottom: 1.4em;
}
.testimonials .testimonial .content p {
  font-size: inherit;
}
.testimonials .testimonial .contact {
  display: block;
  line-height: 1em;
  margin-bottom: 10px;
}
.testimonials .testimonial .company {
  color: #00557B;
  font-size: 1.2em;
  font-weight: 700;
}
.testimonials .testimonial .company a {
  color: inherit;
}

ul.testimonials {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*#endregion CLIENTS SECTION*/
/*#region RESOURCES SECTION*/
/* Cases Slider */
.cases.slider .slide {
  color: white;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.cases.slider .slide .bgImgWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
}
.cases.slider .slide .bgImgWrapper img.bgImg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.cases.slider .slide .bgImgWrapper .bgImg:before {
  position: relative;
  display: block;
  height: 100%;
  content: " ";
  background-image: -webkit-gradient(linear, left top, right top, from(rgb(0, 0, 0)), color-stop(60%, rgba(63, 108, 118, 0.5)));
  background-image: linear-gradient(to right, rgb(0, 0, 0) 0%, rgba(63, 108, 118, 0.5) 60%);
  opacity: 0.6;
  z-index: 2;
}
.cases.slider .slide .innerContent {
  width: 50%;
  height: 100%;
  position: relative;
  z-index: 3;
  padding: 10em 0 0 5em;
  text-align: left;
  color: #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: none;
}
.cases.slider .slide .innerContent h4 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: #fff;
}
.cases.slider .slide .innerContent h3 {
  margin-top: 0.5em;
  color: #fff;
}
.cases.slider .slide .innerContent h3 a:link, .cases.slider .slide .innerContent h3 a:visited, .cases.slider .slide .innerContent h3 a:active {
  color: #ffffff;
}
.cases.slider .slide .innerContent h3 a:hover, .cases.slider .slide .innerContent h3 a:focus {
  color: #1A3053;
}
.cases.slider button.slick-prev, .cases.slider button.slick-next {
  background-color: #fff;
  opacity: 0.6;
  border-radius: 50%;
  width: 50px;
}
.cases.slider button.slick-prev:before, .cases.slider button.slick-next:before {
  color: #1A3053;
}

/* List (multi item) Views */
.resources > ul.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -1.5rem;
}
.resources > ul.grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 1.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 3rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 3rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 3rem);
          flex-basis: calc((1 / 4) * 100% - 3rem);
}
html.k-ie.k-ie11 .resources > ul.grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
@media (max-width: 1600px) {
  .resources > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .resources > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 3) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
            flex-basis: calc((1 / 3) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .resources > ul.grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media (max-width: 767px) {
  .resources > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .resources > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .resources > ul.grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
.resources > ul.grid > .col {
  margin-bottom: 2em;
  margin-top: 1em;
}
.resources > ul.grid > .col article {
  height: 100%;
}
.resources .innerContent {
  position: relative;
  padding-top: 1em;
  padding-bottom: 1em;
  min-height: 300px;
  border-top: 1px solid #B1B1B1;
}
.resources .innerContent .itemImage {
  width: 100%;
  height: 200px;
  margin-bottom: 1em;
}
.resources .innerContent .itemContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.resources .innerContent .itemContent .contentTypeLabels {
  color: #1A3053;
  /*.labelValuesSeperator {
          display: none;
      }*/
  /*.contentType,
      .subType {
          display: block;
          font-weight: $fontWeightBold;
          text-transform: uppercase;
      }

      .contentType {
          font-size: 3em;
      }

      .subType {
          font-size: 1em;
      }*/
}
.resources .innerContent .itemContent .contentTypeLabels a {
  color: #1A3053;
}
.resources .innerContent .itemContent .contentTypeLabels a:hover, .resources .innerContent .itemContent .contentTypeLabels a:active {
  color: #00557B;
  text-decoration: none;
}
.resources .innerContent .itemContent h3 {
  margin-bottom: 0;
}
.resources .innerContent .itemContent span.caseCitation {
  display: block;
  margin: 1rem 0 0 0;
  color: #1A3053;
  font-weight: 400;
}
.resources .innerContent .itemContent .authorTeam {
  display: block;
  margin: 1rem 0;
  color: #00557B;
  font-weight: 400;
  /*white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;*/
}
.resources .innerContent .itemContent .authorTeam span.labelValuesSeperator:before {
  content: ": ";
}
.resources .innerContent .itemContent .authorTeam a {
  color: #00557B;
  font-size: 0.9em;
  font-weight: 400;
  font-style: normal;
}
.resources .innerContent .itemContent .authorTeam a:hover, .resources .innerContent .itemContent .authorTeam afocus {
  color: #64002E;
  text-decoration: none;
}
.resources .innerContent .itemContent .authorTeam span.seperator:before {
  content: " | ";
}
.resources .innerContent .itemContent span.dates {
  display: block;
  margin: 0.5em 0;
  font-weight: 400;
}
.resources .innerContent .itemContent div.summary {
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5em 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.resources.publications > ul.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.resources.publications > ul.grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
html.k-ie.k-ie11 .resources.publications > ul.grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
@media only screen and (max-width: 1099px) {
  .resources.publications > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .resources.publications > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 3) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
            flex-basis: calc((1 / 3) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .resources.publications > ul.grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .resources.publications > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .resources.publications > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .resources.publications > ul.grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media only screen and (max-width: 767px) {
  .resources.publications > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .resources.publications > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .resources.publications > ul.grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
.resources.publications > ul.grid > .col {
  margin-bottom: 1em;
}
.resources.rxip > ul.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.resources.rxip > ul.grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
          flex-basis: calc((1 / 2) * 100% - 1rem);
}
html.k-ie.k-ie11 .resources.rxip > ul.grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
@media (max-width: 1024px) {
  .resources.rxip > ul.grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .resources.rxip > ul.grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .resources.rxip > ul.grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
.resources.rxip .innerContent {
  position: relative;
  padding-bottom: 3em;
  min-height: 600px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.resources.rxip .innerContent .itemImage {
  height: 300px;
  width: 40%;
  padding-right: 1em;
}
.resources.rxip .innerContent .itemContent {
  width: 60%;
}
.resources.rxip .innerContent .itemContent h3 {
  margin-top: 0;
}
.resources.rxip.nophoto .innerContent {
  min-height: 500px;
}
.resources.rxip.nophoto .innerContent .itemContent {
  width: 100%;
}
.resources.rxip.nophoto .innerContent .itemContent span.overflowSummary {
  display: block;
  width: 100%;
  max-height: 350px;
  overflow: hidden;
}

.resource.detailView.publication {
  padding: 50px 0px 50px 0px;
}
.resource.detailView.publication ul, .resource.detailView.publication ol {
  margin-left: 1em;
  list-style: none;
}
.resource.detailView.publication ul li, .resource.detailView.publication ol li {
  list-style: none;
}
.resource.detailView.publication ul li::before, .resource.detailView.publication ol li::before {
  content: "•"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #64002E; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.resource.detailView .pageBanner {
  width: 100%;
  min-height: 35em;
  margin-bottom: 2em;
  overflow: hidden;
}
.resource.detailView .pageBanner a {
  color: #fff;
}
.resource.detailView .pageBanner a:hover {
  color: #fff;
}
.resource.detailView .pageBanner .bgImgWrapper {
  position: absolute;
}
.resource.detailView .pageBanner .bgImgWrapper .bgImg:after {
  position: relative;
  display: block;
  height: 100%;
  content: " ";
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.2)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2) 100%);
}
.resource.detailView .pageBanner .innerContainer {
  position: relative;
  padding-top: 2em;
  padding-bottom: 2em;
}
.resource.detailView .pageBanner .innerContainer span.caseCitation {
  display: block;
  margin: -1.5em 0 2em 0;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels {
  color: white;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .labelValuesSeperator {
  display: none;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .itemSeperator {
  margin-left: 0.5em;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels a {
  color: #fff;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels a:hover, .resource.detailView .pageBanner .innerContainer .contentTypeLabels a:active {
  color: #fff;
  text-decoration: none;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .contentType,
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .subType {
  font-weight: 700;
  text-transform: uppercase;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .contentType {
  font-size: 1em;
}
.resource.detailView .pageBanner .innerContainer .contentTypeLabels .subType {
  font-size: 1em;
}
.resource.detailView .pageBanner .innerContainer .related {
  margin: 0.5em 0;
}
.resource.detailView .pageBanner .innerContainer .related ul.grid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
  font-size: 1em;
  padding-bottom: 1em;
}
.resource.detailView .pageBanner .innerContainer .related ul.grid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 .resource.detailView .pageBanner .innerContainer .related ul.grid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.resource.detailView .pageBanner .innerContainer .related ul.grid li a:after {
  content: "|";
  padding-left: 0.5em;
}
.resource.detailView .pageBanner .innerContainer .related ul.grid li:last-child a:after {
  content: "";
}
.resource.detailView .pageBanner .innerContainer .authorTeam {
  margin-top: 1.5em;
}
.resource.detailView .pageBanner .innerContainer span.dates {
  display: block;
  margin: 0.5em 0;
}
.resource.detailView .pageBanner .innerContainer ul.sharingLinks li.sharingLink button i {
  color: #fff;
}
.resource.detailView .mainContent {
  padding-top: 2em;
}
.resource.detailView .secondaryContent {
  -webkit-flex-shrink: 1;
      -ms-flex-negative: 1;
          flex-shrink: 1;
}

.resourcesSlider .slick-track {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.resourcesSlider ul.slick-dots {
  display: none !important;
}
.resourcesSlider .item.slide {
  width: 33%;
  margin: 0;
  -webkit-box-shadow: 0 0 36px rgba(94, 106, 113, 0.2);
          box-shadow: 0 0 36px rgba(94, 106, 113, 0.2);
  border: 1px solid #dbdbdd;
  vertical-align: top;
  height: auto !important;
}

.filters {
  padding: 3em 0;
}
.filters form .filterOptions {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.filters form .filterOptions > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 .filters form .filterOptions > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.filters form .filterOptions fieldset {
  border: none;
  padding: 0;
  font-size: 1rem;
  position: relative;
}
.filters form .filterOptions fieldset > h3,
.filters form .filterOptions fieldset > label {
  color: #1A3053;
  margin: 0;
  display: block;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
}
.filters form .filterOptions[data-f-display-type=checkboxes] fieldset {
  width: 100%;
  /*border: 1px solid tomato;*/
}
.filters form .filterOptions[data-f-display-type=checkboxes] .fields {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.filters form .filterOptions[data-f-display-type=checkboxes] .fields > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 1rem);
          flex-basis: calc((1 / 3) * 100% - 1rem);
}
html.k-ie.k-ie11 .filters form .filterOptions[data-f-display-type=checkboxes] .fields > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.filters form .filterOptions[data-f-display-type=checkboxes] .fields .field {
  padding: 0.2em 0;
  margin-bottom: 0;
}
.filters form .filterOptions[data-f-display-type=dropdowns] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.filters form .filterOptions[data-f-display-type=dropdowns] > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 4) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 4) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 4) * 100% - 1rem);
          flex-basis: calc((1 / 4) * 100% - 1rem);
}
html.k-ie.k-ie11 .filters form .filterOptions[data-f-display-type=dropdowns] > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
@media (max-width: 767px) {
  .filters form .filterOptions[data-f-display-type=dropdowns] {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .filters form .filterOptions[data-f-display-type=dropdowns] > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 2) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
            flex-basis: calc((1 / 2) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .filters form .filterOptions[data-f-display-type=dropdowns] > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
@media (max-width: 500px) {
  .filters form .filterOptions[data-f-display-type=dropdowns] {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
  }
  .filters form .filterOptions[data-f-display-type=dropdowns] > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 .filters form .filterOptions[data-f-display-type=dropdowns] > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
.filters form .filterOptions[data-f-display-type=dropdowns] .col {
  /*min-width: 280px;*/
}
.filters form .filterOptions[data-f-display-type=dropdowns] fieldset {
  /*border: 1px solid lime;*/
}
.filters h3 {
  text-transform: uppercase;
  font-size: 1em;
  margin-top: 1em;
}
.filters button {
  width: auto;
  height: 2.6em;
  min-width: initial;
  margin-top: 1.2rem;
}
.filters button[type=reset] {
  width: 100%;
}
.filters input[type=checkbox] {
  cursor: pointer;
  width: 1px;
  height: 1px;
  position: absolute;
  visibility: hidden;
}
.filters input[type=checkbox] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 1em;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=checkbox] + label::-moz-selection {
  background-color: transparent;
}
.filters input[type=checkbox] + label::selection {
  background-color: transparent;
}
.filters input[type=checkbox] + label:hover {
  color: #64002E;
}
.filters input[type=checkbox] + label:before, .filters input[type=checkbox] + label:after {
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  font-size: 1rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-right: 0.4em;
}
.filters input[type=checkbox] + label:before {
  content: "\f0c8";
  color: #64002E;
}
.filters input[type=checkbox] + label:after {
  top: 2px;
  left: 1px;
  font-size: 0.8em;
  position: absolute;
  content: "\f00c";
  color: #00557B;
  -webkit-transform: scale(0);
          transform: scale(0);
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
}
.filters input[type=checkbox]:checked + label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.filters .keywordSearch {
  border: 1px solid #64002E;
}
.filters input[type=text] {
  width: 100%;
  /*max-width: 300px;*/
  color: #1A3053;
  padding: 0.2em 1em;
  padding-right: 3em;
  height: 62.39px;
  background-image: url(/CFImages/Icons/search-pipe.svg);
  background-repeat: no-repeat;
  background-position: right 1em center;
  text-overflow: ellipsis;
}
.filters input[type=text]::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=text]:-moz-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=text]::-moz-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=text]:-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.filters input[type=text]:hover::-webkit-input-placeholder, .filters input[type=text]:focus::-webkit-input-placeholder {
  opacity: 0.4;
}
.filters input[type=text]:hover:-moz-placeholder, .filters input[type=text]:focus:-moz-placeholder {
  opacity: 0.4;
}
.filters input[type=text]:hover::-moz-placeholder, .filters input[type=text]:focus::-moz-placeholder {
  opacity: 0.4;
}
.filters input[type=text]:hover:-ms-input-placeholder, .filters input[type=text]:focus:-ms-input-placeholder {
  opacity: 0.4;
}
.filters input[type=text]:focus {
  outline: 2px solid #64002E;
}
.filters select {
  width: 100%;
  /*width: 280px;*/
  color: #1A3053;
  cursor: pointer;
  border: 1px solid #64002E;
  padding: 0.2em 1em;
  font-size: 1rem;
  line-height: 1;
  font-family: "Gotham A", "Gotham B", Arial, sans-serif;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url(/CFImages/Icons/triangle-down-purple.svg);
  background-repeat: no-repeat;
  background-position: right 1em center;
  background-size: 0.6em auto;
  padding-right: 1.6em;
  height: 62.39px;
  text-transform: uppercase;
}
.filters select:focus {
  outline: none;
  outline: 2px solid #64002E;
}
.filters select > option {
  text-transform: none;
}
.filters select > option:checked, .filters select > option:hover {
  background-color: #E4EAED;
}
.filters #currentFilters {
  display: none;
  padding: 20px 30px;
  margin: 0 0 10px 0;
  border: 1px solid #ccc;
  background-color: #fff;
}
.filters #currentFilters.showThis {
  display: inline-block;
  width: 48%;
  vertical-align: top;
}
.filters #currentFilters div.filterDataGroup {
  margin-bottom: 10px;
}
.filters #currentFilters div.filterDataGroup span.removeFilter {
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
  padding: 10px 30px 10px 10px;
  margin-left: 10px;
}
.filters #currentFilters div.filterDataGroup span.removeFilter:hover {
  cursor: pointer;
  background-color: red;
  color: #fff;
}
.filters #currentFilters div.filterDataGroup span.removeFilter:after {
  content: "x";
  position: absolute;
  right: 10px;
}

.mixContainer .metaDisplayForTesting {
  display: none !important;
}
.mixContainer button {
  min-width: initial;
}
.mixContainer span.metaDisplayForTesting {
  display: block;
  padding: 20px 30px;
  margin: 0 0 10px 0;
  border: 1px solid #ccc;
  background-color: #eee;
}

.mixitup-page-list {
  margin-top: 1rem;
  text-align: center;
}
.mixitup-page-list button {
  min-width: initial;
}
.mixitup-page-list .mixitup-control {
  margin: 0;
}
.mixitup-page-list .mixitup-control.mixitup-control-prev, .mixitup-page-list .mixitup-control.mixitup-control-next {
  color: transparent !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1em;
}
.mixitup-page-list .mixitup-control.mixitup-control-prev {
  background-image: url(/CFImages/Icons/arrow-left-teal.svg);
}
.mixitup-page-list .mixitup-control.mixitup-control-next {
  background-image: url(/CFImages/Icons/arrow-right-teal.svg);
}
.mixitup-page-list .mixitup-control.mixitup-control-disabled {
  /*opacity: 0.4;*/
}
.mixitup-page-list .mixitup-control.mixitup-control-active {
  color: #64002E !important;
}

.mixitup-page-stats {
  color: #1A3053;
  text-align: center;
  font-family: "Gotham A", "Gotham B", Arial, sans-serif;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

/*#endregion RESOURCES SECTION*/
/*#region FACTS / FACT GROUPS */
.factgroups {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  margin: 1.5em 0;
}
.factgroups .factGroupOuter {
  position: relative;
  padding: 4em 1em;
}
.factgroups .bgImgWrapper .bgImg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.factgroups .facts {
  top: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  z-index: 1;
  position: relative;
  text-align: center;
  overflow: hidden;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0;
}
.factgroups .facts .fact {
  color: #64002E;
  width: 16em;
  margin: 2em auto;
  display: inline-block;
  position: relative;
  border: 1px solid #B1B1B1;
  background-color: #fff;
  border-radius: 50%;
}
.factgroups .facts .fact {
  font-size: 11px;
}
@media screen and (min-width: 767px) {
  .factgroups .facts .fact {
    font-size: -webkit-calc(11px + 9 * ((100vw - 767px) / 332));
    font-size: calc(11px + 9 * ((100vw - 767px) / 332));
  }
}
@media screen and (min-width: 1099px) {
  .factgroups .facts .fact {
    font-size: 20px;
  }
}
.factgroups .facts .fact:before { /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.factgroups .facts .fact:after { /* to clear float */
  content: "";
  display: table;
  clear: both;
}
.factgroups .facts .fact .factInner {
  top: 50%;
  left: 0;
  right: 0;
  padding: 1.2em;
  max-width: 100%;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.factgroups .facts .fact .factInner .factInnerTop {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 0;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.factgroups .facts .fact .factInner .factInnerTop > span {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0;
  vertical-align: top;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: auto;
}
html.k-ie.k-ie11 .factgroups .facts .fact .factInner .factInnerTop > span > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.factgroups .facts .fact .factInner .factInnerTop .value, .factgroups .facts .fact .factInner .factInnerTop .units {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.factgroups .facts .fact .factInner .factInnerTop .value {
  font-size: 4em;
  line-height: 1.2em;
  font-weight: 700;
}
.factgroups .facts .fact .factInner .factInnerTop .units {
  font-size: 2em;
  line-height: 1em;
  font-weight: 300;
  text-transform: uppercase;
}
.factgroups .facts .fact .factInner .factInnerTop:after {
  content: "";
  height: 0.5em;
  width: 2em;
  background-color: #1A3053;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  bottom: 0;
}
.factgroups .facts .fact .factInner .factInnerBottom {
  position: relative;
  padding: 1em 0.3em 0;
}
.factgroups .facts .fact .factInner .factInnerBottom .subject {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.1em;
  text-transform: uppercase;
  white-space: pre-wrap;
}

/*#endregion FACTS / FACT GROUPS */
/*#region CONTACT SECTION*/
.quickContact p {
  margin: 12px 0;
}
.quickContact p span.required {
  color: #cc0000;
  margin-top: 8px;
  width: 100%;
  font-size: 12px;
  line-height: 1em;
  margin-bottom: 5px !important;
}
.quickContact p span.required:last-of-type {
  margin-bottom: 20px !important;
}
.quickContact input, .quickContact textarea, .quickContact select {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 13px 10px 11px !important;
  font-style: normal !important;
  color: black !important;
  font-size: 18px !important;
  font-family: "Gotham A", "Gotham B", Arial, sans-serif !important;
  font-weight: 400 !important;
  -webkit-box-sizing: border-box !important;
          box-sizing: border-box !important;
  height: auto !important;
  border: 1px solid grey !important;
  width: 100%;
  letter-spacing: 0.02em;
}
.quickContact .RadInput {
  width: 100% !important;
}
.quickContact select {
  width: 100%;
  padding: 12px 8px !important;
}
.quickContact textarea {
  padding: 12px 10px 20px !important;
  min-height: 100px;
}
.quickContact .fieldLabel {
  color: white;
  width: 100%;
  font-size: 12px;
  color: grey;
  margin-bottom: 1px !important;
  letter-spacing: 0.05em;
  display: block;
  text-transform: uppercase;
}
.quickContact .fieldLabel em {
  float: right;
  font-size: 10px;
}
.quickContact .submit .button {
  margin-top: 15px;
}

.footer .quickContact .fieldLabel {
  color: #b3b3b3;
  margin-bottom: 0px !important;
}
.footer .quickContact input, .footer .quickContact input[type=text], .footer .quickContact textarea, .footer .quickContact select {
  border: 1px solid rgba(128, 128, 128, 0.5) !important;
  background-color: grey !important;
  color: white !important;
  font-weight: 300 !important;
}
.footer .quickContact h3 {
  color: white;
}
.footer .quickContact p:first-child {
  margin-top: 0;
}
.footer .quickContact p .required {
  color: grey;
  margin-bottom: 5px !important;
}
.footer .quickContact p .required:last-of-type {
  margin-bottom: 20px !important;
}

/*#endregion CONTACT SECTION*/
/*#region WRAPPERS*/
.plainWrapper, .sectorsWrapper, .projectsWrapper, .resourcesWrapper {
  min-height: 400px;
  padding: 2em 0;
}

.plainWrapper {
  text-align: center;
}

.blueWrapper {
  background-color: #E6F6FA;
}

.sectorsWrapper {
  color: white;
  text-align: right;
  background-color: #1A3053;
  padding-top: 60px;
  padding-bottom: 140px;
}
.sectorsWrapper .sectorsRight {
  position: relative;
  /*padding-bottom: 60px;*/
}
.sectorsWrapper .sectorsRight .button {
  bottom: 0;
  right: 0;
  position: absolute;
}
.sectorsWrapper .sectorsImage img {
  max-width: 100%;
}
.sectorsWrapper h2, .sectorsWrapper h3, .sectorsWrapper h4, .sectorsWrapper p {
  color: #fff;
}
.sectorsWrapper h3 {
  font-size: 5em;
  font-weight: 700;
  line-height: 0.9;
  /*max-width: 100vw;*/
}
@media (min-width: 1025px) {
  .sectorsWrapper h3 {
    margin-left: -100%;
  }
}
.sectorsWrapper h4 {
  font-weight: 400;
  font-size: 30px;
  line-height: 0.8em;
  margin-bottom: 40px;
}

.greyWrapper {
  padding: 4em 0;
  background-color: #F1F1F1;
}

/*#endregion WRAPPERS*/
/* #region PROJECTS SECTION*/
.projects.related .carousel .slick-list {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -1rem;
}
.projects.related .carousel .slick-list > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 1rem;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 2rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 2rem);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 2rem);
          flex-basis: calc((1 / 3) * 100% - 2rem);
}
html.k-ie.k-ie11 .projects.related .carousel .slick-list > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.projects.related .carousel .slide {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 1rem;
  vertical-align: top;
  /*width: calc((1 / 3) * 100% - 2rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 3) * 100% - 2rem);
      -ms-flex-preferred-size: calc((1 / 3) * 100% - 2rem);
          flex-basis: calc((1 / 3) * 100% - 2rem);
}
html.k-ie.k-ie11 .projects.related .carousel .slide > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}

article.projectCard {
  width: 100%;
  position: relative;
  background-color: white;
  -webkit-box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.16);
}
article.projectCard:before { /* sets the height and then effectively removes itself from flow so as not to interfere with siblings */
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
article.projectCard:after { /* to clear float */
  content: "";
  display: table;
  clear: both;
}
/*#region RELATED CONTENT SECTION*/
.itemGrid2 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -10px;
}
.itemGrid2 > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 10px;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 20px);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 20px);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 20px);
          flex-basis: calc((1 / 2) * 100% - 20px);
}
html.k-ie.k-ie11 .itemGrid2 > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}

.carousel {
  margin: 0;
  padding: 0;
}
.carousel li {
  margin: 0 3px;
  padding: 0;
}
.carousel li:before {
  content: "" !important;
  margin: 0 !important;
}

.slider .slide {
  min-height: 500px;
}

/*#endregion RELATED CONTENT SECTION*/
.socialLinks em, .socialLinks i, .sharingLinks em, .sharingLinks i, .searchBox em, .searchBox i {
  font-style: normal !important;
}

.viewMoreOuter {
  margin: 0em auto 3em;
  text-align: center;
}

.mainContent {
  padding-right: 3em;
}

.secondaryContent h3 {
  color: #1A3053;
  text-transform: uppercase;
  font-size: 1em;
  margin-top: 3em;
}

/*#region Filtered Content Items SECTION*/
.filteredContent #loadingCounter {
  margin-top: 0px;
}
.filteredContent .searchByInitialContainer {
  margin: 2rem 0;
}
.filteredContent .searchByInitialContainer > .grid > .col a.initialFilter {
  cursor: pointer;
  text-decoration: none;
}
.filteredContent .searchByInitialContainer > .grid > .col a.initialFilter.selected {
  color: #1A3053;
}
.filteredContent .buttonsContainer .button i {
  margin-left: 2rem;
}
.filteredContent .buttonsContainer .button:not(:last-child) {
  margin-right: 1rem;
}
.filteredContent .loadMoreContainer button i {
  margin-right: 0.4rem;
}

.advancedFiltersContainer {
  padding: 3rem;
  background-color: white;
  width: 100%;
  height: auto;
  max-width: 60rem;
  display: none;
  -webkit-box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.16);
  position: relative;
}
.advancedFiltersContainer .closeFiltersButton {
  background-color: #1A3053;
  background-image: none;
  border: none;
  color: black;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 1.5rem;
  right: 3rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}
.advancedFiltersContainer .closeFiltersButton .fa-times {
  color: white;
}
.advancedFiltersContainer .closeFiltersButton:hover, .advancedFiltersContainer .closeFiltersButton:focus, .advancedFiltersContainer .closeFiltersButton:focus-within, .advancedFiltersContainer .closeFiltersButton:active {
  background-color: #64002E;
}
.advancedFiltersContainer .contentFilters .grid.fields {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.advancedFiltersContainer .contentFilters .grid.fields > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
          flex-basis: calc((1 / 2) * 100% - 1rem);
}
html.k-ie.k-ie11 .advancedFiltersContainer .contentFilters .grid.fields > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.advancedFiltersContainer .contentFilters .filtersGrid {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.advancedFiltersContainer .contentFilters .filtersGrid > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 2) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 2) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 2) * 100% - 1rem);
          flex-basis: calc((1 / 2) * 100% - 1rem);
}
html.k-ie.k-ie11 .advancedFiltersContainer .contentFilters .filtersGrid > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.advancedFiltersContainer .contentFilters fieldset .grid.checkboxes {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0 -0.5rem;
}
.advancedFiltersContainer .contentFilters fieldset .grid.checkboxes > .col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  /*order: 0;*/
  width: initial;
  max-width: 100%;
  margin: 0 0.5rem;
  vertical-align: top;
  /*width: calc((1 / 1) * 100% - 1rem);*/
  -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
      -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
          flex-basis: calc((1 / 1) * 100% - 1rem);
}
html.k-ie.k-ie11 .advancedFiltersContainer .contentFilters fieldset .grid.checkboxes > .col > * {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.advancedFiltersContainer .contentFilters fieldset .grid.checkboxes > .col {
  /*margin: 0.2rem 0;*/
  margin-bottom: 0.3rem;
}
.advancedFiltersContainer .contentFilters fieldset .toggleAction {
  cursor: pointer;
  position: relative;
  padding: 1.4rem 0;
  border-bottom: 1px solid #B1B1B1;
}
.advancedFiltersContainer .contentFilters fieldset .toggleAction h3 {
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
}
.advancedFiltersContainer .contentFilters fieldset .toggleAction:after {
  content: "\f078";
  color: #1A3053;
  font-family: "Font Awesome 5 Pro";
  font-size: 0.6rem;
  position: absolute;
  right: 1rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.advancedFiltersContainer .contentFilters fieldset .toggleContent {
  display: none;
  position: relative;
  background-color: #E6F6FA;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 14rem;
}
.advancedFiltersContainer .contentFilters fieldset ul {
  padding: 1rem;
}
#filtersDisplayContainer {
  margin: 1rem 0;
}
#filtersDisplayContainer .activeFilterGroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
#filtersDisplayContainer .activeFilterGroup .activeFilterTag {
  border: 1px solid #E4EAED;
  padding: 0.4rem 1rem;
  cursor: pointer;
  position: relative;
  padding-right: 2rem;
  margin-bottom: 0.6rem;
}
#filtersDisplayContainer .activeFilterGroup .activeFilterTag:not(:last-of-type) {
  margin-right: 0.6rem;
}
#filtersDisplayContainer .activeFilterGroup .activeFilterTag:after {
  content: "x";
  right: 1rem;
  color: #1A3053;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.filtersPopupWrapper {
  width: 100%;
  left: 0 !important;
}
.filtersPopupWrapper .advancedFiltersContainer {
  margin: 0 auto;
  display: block !important;
}

#loadingIndicator {
  height: 200px;
  display: none;
}
#loadingIndicator svg {
  margin: 0 auto;
}
#loadingIndicator.loading {
  content: "loading";
  line-height: 200px;
  text-align: center;
  display: block;
}

#loadingCounter {
  margin: 2rem 0;
}

.gridTestingPage .bd .grid {
  border: 2px solid purple;
}
.gridTestingPage .bd .grid .col {
  border: 2px dashed grey;
  min-height: 200px;
}

.printOnly {
  display: none;
}

.mobileOnly {
  display: none;
  height: 0;
  width: 0;
}

.programsSearch .advancedFiltersForm .accordionBottom label:hover {
  color: inherit !important;
}

.programs .itemContent {
  color: #64002E;
  text-decoration: underline;
}
.programs .itemContent:hover {
  color: #1A3053;
}

footer {
  z-index: 10;
}

footer .cubicle .exitSurvey {
  position: fixed;
  top: 0;
  left: -6000px;
  padding: 4rem;
  margin: 2rem;
  background-color: rgba(16, 16, 16, 0.98);
  -webkit-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s;
  height: -webkit-calc(100vh - 4rem);
  height: calc(100vh - 4rem);
  overflow-y: scroll;
  text-align: left;
  z-index: 999999;
}
footer .cubicle .exitSurvey p, footer .cubicle .exitSurvey li, footer .cubicle .exitSurvey div strong, footer .cubicle .exitSurvey div label {
  font-size: 1.2em;
}
footer .cubicle .exitSurvey .sf-RadioButtons > div {
  line-height: 1.3em;
}
footer .cubicle .exitSurvey h3, footer .cubicle .exitSurvey a {
  color: white;
}
footer .cubicle .exitSurvey form {
  position: relative;
}
footer .cubicle .exitSurvey strong {
  color: white;
  font-weight: normal;
}
footer .cubicle .exitSurvey.exited {
  left: -6000px;
}
footer .cubicle .exitSurvey.active {
  left: 0;
}
footer .cubicle .exitSurvey.active .surveyExitButton {
  right: 4rem;
  top: 4rem;
}
footer .cubicle .exitSurvey.active.exited {
  left: -6000px;
}
footer .cubicle .exitSurvey.active.exited .surveyExitButton {
  right: -6000px;
}
footer .cubicle .exitSurvey .surveyExitButton {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  right: -6000px;
  top: 4rem;
  -webkit-transition: all 0.6s ease-in-out 0s;
  transition: all 0.6s ease-in-out 0s;
}
footer .cubicle .exitSurvey .surveyExitButton button {
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
  padding: 0.7em 0.9em;
}
@media only screen and (max-width: 1100px) {
  footer .cubicle .exitSurvey {
    padding: 4rem;
    margin: 4.5rem 0.5rem 0.5rem 0.5rem;
  }
  footer .cubicle .exitSurvey h3 {
    font-size: 1.5em;
  }
  footer .cubicle .exitSurvey .surveyExitButton {
    top: 5.5rem;
  }
  footer .cubicle .exitSurvey.active .surveyExitButton {
    top: 5.5rem;
  }
}
@media only screen and (max-width: 850px) {
  footer .cubicle .exitSurvey {
    padding: 4rem;
    margin: 4.5rem 0.5rem 0.5rem 0.5rem;
  }
  footer .cubicle .exitSurvey .grid {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin: 0 -0.5rem;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  footer .cubicle .exitSurvey .grid > .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    /*order: 0;*/
    width: initial;
    max-width: 100%;
    margin: 0 0.5rem;
    vertical-align: top;
    /*width: calc((1 / 1) * 100% - 1rem);*/
    -webkit-flex-basis: -webkit-calc((1 / 1) * 100% - 1rem);
        -ms-flex-preferred-size: calc((1 / 1) * 100% - 1rem);
            flex-basis: calc((1 / 1) * 100% - 1rem);
  }
  html.k-ie.k-ie11 footer .cubicle .exitSurvey .grid > .col > * {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
}
footer .cubicle button {
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 3rem;
}